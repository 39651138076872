<template>
    <div class="dashboard">
     <v-subheader class="d-flex justify-space-between align-center">
             <h3>modification de la commande N°{{ id_commande }}</h3>
             <v-btn color="success" class="rouded-lg">
                 
             </v-btn>
     </v-subheader>
     <main style="background-color: white;">

    <div class="section">
      <div class="container">
      <div class="row">
        <div class="col-md-5 col-md-push-2" >
          <div id="product-main-img" >
            <div class="product-preview" >
              <img :src="affiche.produit?.images" alt="" style="border-radius: 5px"/>
            </div>
          </div>
          <v-divider></v-divider>
          <div style="margin-right"></div>
          <!-- <v-row align="center" style="display: flex; justify-content: center; align-items: center;">
            <v-row justify="center">
              <v-expansion-panels accordion>
                <v-expansion-panel >
                <v-expansion-panel-header><strong>COMMENTAIRES</strong> </v-expansion-panel-header>
                  <v-expansion-panel-content >
                    <div class="msg-container3" >
                      <div  >
                        <strong></strong><br>
                          
                      </div>
                    </div>
                  </v-expansion-panel-content>
                  </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-row> -->
        </div>
        <div class="col-md-7">
          <div class="product-details">

              <h3 class="product-price" style="font-family: Segoe UI; font-size: 38px;"><strong> Nom produit</strong></h3>
              <br><h5 class="product-price" style="font-family: Segoe UI; font-size: 16px;"><strong>Variété : </strong></h5>
              <div class="row">
                <!--------------------------------------------------------------------------------------------------------------->
                                <div class="col-6">
                                  <b-form-group id="input-group-3" label="Quantité :" label-for="input-3" >
                                      <b-form-input id="input-4" placeholder="Quelle est la quantité" v-model="quantite_achat" required ></b-form-input>
                                  </b-form-group>
                                </div>
                <!--------------------------------------------------------------------------------------------------------------->
                                <div class="col-6">
                                  <b-form-group id="input-group-2" label="Unité de mésure :"  label-for="input-2" >
                                    <select class="form-select" aria-label="Default select example">
                                      <option value="Tonne(t)" disabled>  Tonne (T) </option>
                                      <option value="Kilogramme(Kg)" disabled>  Kilogramme (Kg) </option>
                                      <option value="Gramme (g)" disabled>  Gramme (g) </option>
                                      <option value="miligramme (Mg)" disabled>  miligramme (Mg) </option>
                                      <option value="Metre_Cube" disabled>  Metre Cube </option>
                                      <option value="Litre(l)" disabled>  Litre (L) </option>
                                      <option value="Millilitre (Ml)" disabled>  Millilitre (Ml) </option>
                                  </select>
                                  </b-form-group>
                                </div>                
                <!--------------------------------------------------------------------------------------------------------------->
                              </div>






                              <div class="d-flex justify-content-between">
                                <p class="mb-2">Prix du produit :</p>
                                <p class="mb-2">000.00 Fcfa</p>
                              </div>
                              <div class="d-flex justify-content-between">
                                <p class="mb-2">Transport :</p>
                                <p class="mb-2">000.00 Fcfa</p>
                              </div>
                              <div class="d-flex justify-content-between mb-4">
                                <p class="mb-2">Total :</p>
                                <p class="mb-2">000.00 Fcafa</p>
                              </div>
                              <button type="button" class="btn btn-info btn-block btn-lg" @click="update_commande()">
                                <div class="d-flex justify-content-between">
                                  <span></span>
                                  <span>Modifier la commande<i class="fas fa-long-arrow-alt-right ms-2"></i></span>
                                </div>
                              </button>
                              <button type="button" class="btn btn-danger btn-block btn-lg" @click="confirm2()">
                                <div class="d-flex justify-content-between">
                                  <span></span>
                                  <span>Supprimer la commande<i class="fas fa-long-arrow-alt-right ms-2"></i></span>
                                </div>
                              </button>










          </div>
        </div>
      </div>
      </div>
      </div>
      <hr>
      <template>
        <b-modal size="xl" id="modal-scoped">
          <template #modal-header="{ close }">
            <router-link to="/commande"> <b-button size="sm" variant="outline-danger" @click="">
              Ma liste de commande
            </b-button></router-link>
            <h5>Quel message voulez vous ecouter</h5>
          </template>
        <section class="h-100 h-custom">
            <div class="container py-5 h-100">
              <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col">
                  <div class="card">
                    <div class="card-body p-4">
                      <div class="row">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <template #modal-footer="{ cancel }">
            <b-button size="sm" variant="danger" @click="cancel()">
              Annulé
            </b-button>
          </template>
        </b-modal>
      </template>    
  </main>
    </div>
 </template>
 
 <script>
 import { mapActions, mapGetters } from "vuex";
 
 export default {
 
   data() {
     return {
       affiche: "",
       id_commande: null,
       id_produit: null,
       quantite_achat: '',
       liste_commande_temp: "",
        liste_commande: [],
        quantie_achats: [],
     };
   },
   components: {
   },
 
   watch: {
     produit() {
       console.log(this.produit.produit);
       if (this.produit) {
         this.affiche = this.produit;
       }
     },
     COMMANDE_LIST() {
        if (this.COMMANDE_LIST) {
          console.log(this.COMMANDE_LIST);
          console.log(this.COMMANDE_LIST.original.object);
          this.liste_commande = this.COMMANDE_LIST.original.object;
          this.liste_commande_temp = this.liste_commande.filter(element => element.id == this.id_commande)
          console.log(this.liste_commande_temp);
          this.quantite_achat = this.liste_commande_temp.quantite_acheter;
          console.log(this.liste_commande_temp.quantite_acheter);
        }
      },
   },

   created() {
    this.id_commande = this.$route.params.id
    this.GET_USER_COMMANDE();
    },

   computed: {
     ...mapGetters(["produit", "COMMANDE_UPDATE", "COMMANDE_LIST"]),
   },
   mounted() {
     this.getProduit(this.id_commande);
   },
 
   methods: {
     ...mapActions(["getProduit", "PUT_USER_COMMANDE", "GET_USER_COMMANDE"]),
     confirm2() {
        this.$confirm.require({
          message: 'Voulez-vous vraiment supprimé cette publication ?',
          header: 'Suppression',
          icon: 'pi pi-info-circle',
          acceptClass: 'p-button-danger',
          accept: () => {
            this.$toast.add({severity:'error', detail:'Suppression réussi', life: 3000});
          },
          reject: () => {
          }
        });
      },
      update_commande() {
          let commande_a_modifier = {
		          quantite_acheter: this.quantite_achat,
		          utilisateur_id: 1,
		          id_produit: this.id_commande,
          };
        console.log(commande_a_modifier);
        // this.PUT_USER_COMMANDE(commande_a_modifier);
      }
   },
 };
 </script>