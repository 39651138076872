<template>
  <main>
        <section class="gray-boxed-wrapper home-one__boxed" style="margin-top:-50px">
            <img src="assets/images/icons/home-1-blog-bg.png" alt="" class="home-one__boxed-bg">
            <section class="blog-home-two blog-home-one">
                <div class="container">
                    <!-- <div class="row top-row"> -->
                        <!-- <div class="col-lg-6"> -->
                            <!-- <div class="block-title text-center"> -->
                                <!-- <h3>Catégories</h3> -->
                                <!-- <p class="block-text">Mois de </p> -->
                            <!-- </div> -->
                            <!-- /.block-title -->
                        <!-- </div> -->
                        <!-- /.col-lg-6 -->
                        <!-- <div class="col-lg-2"> -->
                            
                        <!-- </div> -->
                        <!-- /.col-lg-6 -->
                        <!-- <div class="col-lg-2"> -->
                          <!-- <b-button pill variant="success">Voir tout</b-button>
                              <b-button variant="outline-info" class="mb-2">
                              <b-icon icon="megaphone-fill" aria-hidden="true"></b-icon> megaphone
                          </b-button> -->

                        <!-- </div> -->
                        <!-- /.col-lg-6 -->
                    <!-- </div> -->
                    <!-- /.row -->
                    <div class="row">
                        <a class="thm-btn text-center"><strong> CATEGORIES PRODUITS</strong></a>
                        <div class="col-md-12 col-lg-4">
                            <div class="blog-card">
                                <div class="blog-card__image">
                                    <img src="../../assets/images/u_fruits.jpg" width="100" height="250" alt="Best Way to Do Eco and Agriculture">
                                    <!-- <a href=""></a> -->
                                </div><!-- /.blog-card__image -->
                                <div class="blog-card__content">
                                    <div class="blog-card__date"></div><!-- /.blog-card__date -->
                                    <!-- <div class="blog-card__meta">
                                        <a href="blog-details.html"><i class="far fa-user-circle"></i> by Admin</a>
                                        <a href="blog-details.html"><i class="far fa-comments"></i> 2 Comments</a>
                                    </div>
                                    <h3><a href="blog-details.html">Best Way to Do Eco and Agriculture</a></h3> -->
                                    <router-link to="/categorie_fruit"><a class="thm-btn">FRUITS</a></router-link> 
                                </div><!-- /.blog-card__content -->
                            </div><!-- /.blog-card -->
                        </div>
                        <div class="col-md-12 col-lg-4">
                            <div class="blog-card">
                                <div class="blog-card__image">
                                    <img src="../../assets/images/u_legumes.jpg" width="100" height="250" alt="Leverage agile frameworks to provide">
                                    <!-- <a href="blog-details.html"></a> -->
                                </div><!-- /.blog-card__image -->
                                <div class="blog-card__content">
                                    <div class="blog-card__date"></div><!-- /.blog-card__date -->
                                    <!-- <div class="blog-card__meta">
                                        <a href="blog-details.html"><i class="far fa-user-circle"></i> by Admin</a>
                                        <a href="blog-details.html"><i class="far fa-comments"></i> 2 Comments</a>
                                    </div>
                                    <h3><a href="blog-details.html">Leverage agile frameworks to provide</a></h3> -->
                                    <router-link to="/categorie_legume"><a class="thm-btn">LEGUMES</a></router-link><!-- /.thm-btn -->
                                </div><!-- /.blog-card__content -->
                            </div><!-- /.blog-card -->
                        </div>
                        <div class="col-md-12 col-lg-4">
                            <div class="blog-card">
                                <div class="blog-card__image">
                                    <img src="../../assets/images/u_cereales.jpg" width="100" height="250" alt="Organically grow the holistic world view">
                                    <!-- <a href="blog-details.html"></a> -->
                                </div><!-- /.blog-card__image -->
                                <div class="blog-card__content">
                                    <div class="blog-card__date"></div><!-- /.blog-card__date -->
                                    <!-- <div class="blog-card__meta">
                                        <a href="blog-details.html"><i class="far fa-user-circle"></i> by Admin</a>
                                        <a href="blog-details.html"><i class="far fa-comments"></i> 2 Comments</a>
                                    </div> -->
                                    <!-- <h3><a href="blog-details.html">Organically grow the holistic world view</a></h3> -->
                                    <router-link to="/categorie_cereale"><a class="thm-btn">CEREALES</a></router-link><!-- /.thm-btn -->
                                </div><!-- /.blog-card__content -->
                            </div><!-- /.blog-card -->
                        </div>
                    </div><!-- /.row -->
                    <div class="row">
                        <div class="col-md-12 col-lg-4">
                            <div class="blog-card">
                                <div class="blog-card__image">
                                    <img src="../../assets/images/u_tubercules.png" width="100" height="250" alt="Best Way to Do Eco and Agriculture">
                                    <!-- <a href=""></a> -->
                                </div><!-- /.blog-card__image -->
                                <div class="blog-card__content">
                                    <div class="blog-card__date"></div><!-- /.blog-card__date -->
                                    <!-- <div class="blog-card__meta">
                                        <a href="blog-details.html"><i class="far fa-user-circle"></i> by Admin</a>
                                        <a href="blog-details.html"><i class="far fa-comments"></i> 2 Comments</a>
                                    </div>
                                    <h3><a href="blog-details.html">Best Way to Do Eco and Agriculture</a></h3> -->
                                    <router-link to="/categorie_tubercule"><a class="thm-btn">TUBERCULES</a></router-link><!-- /.thm-btn -->
                                </div><!-- /.blog-card__content -->
                            </div><!-- /.blog-card -->
                        </div>
                        <div class="col-md-12 col-lg-4">
                            <div class="blog-card">
                                <div class="blog-card__image">
                                    <img src="../../assets/images/u_noix.jpeg" width="100" height="250" alt="Leverage agile frameworks to provide">
                                    <!-- <a href="blog-details.html"></a> -->
                                </div><!-- /.blog-card__image -->
                                <div class="blog-card__content">
                                    <div class="blog-card__date"></div><!-- /.blog-card__date -->
                                    <!-- <div class="blog-card__meta">
                                        <a href="blog-details.html"><i class="far fa-user-circle"></i> by Admin</a>
                                        <a href="blog-details.html"><i class="far fa-comments"></i> 2 Comments</a>
                                    </div>
                                    <h3><a href="blog-details.html">Leverage agile frameworks to provide</a></h3> -->
                                    <router-link to="/categorie_noix"><a class="thm-btn">NOIX</a></router-link><!-- /.thm-btn -->
                                </div><!-- /.blog-card__content -->
                            </div><!-- /.blog-card -->
                        </div>
                        <div class="col-md-12 col-lg-4">
                            <div class="blog-card">
                                <div class="blog-card__image">
                                    <img src="../../assets/images/u_toute_cat.png" width="100" height="250" alt="Organically grow the holistic world view">
                                    <!-- <a href="blog-details.html"></a> -->
                                </div><!-- /.blog-card__image -->
                                <div class="blog-card__content">
                                    <div class="blog-card__date"></div><!-- /.blog-card__date -->
                                    <!-- <div class="blog-card__meta">
                                        <a href="blog-details.html"><i class="far fa-user-circle"></i> by Admin</a>
                                        <a href="blog-details.html"><i class="far fa-comments"></i> 2 Comments</a>
                                    </div> -->
                                    <!-- <h3><a href="blog-details.html">Organically grow the holistic world view</a></h3> -->
                                    <router-link to="/services"><a class="thm-btn">VOIR TOUT</a></router-link>
                                </div><!-- /.blog-card__content -->
                            </div><!-- /.blog-card -->
                        </div>
                        <!-- <h5 class="text-center">Chez De Nos Terres vous trouverez des produits agricoles diversifiés, de meilleures qualités et de saison.</h5> -->
                    </div>
                    <br>
                    <br>
                  <hr />
                </div><!-- /.container -->
            </section><!-- /.blog-home-two -->
        </section><!-- /.gray-boxed-wrapper -->
  </main>
</template>

<script>
export default {
  name: "service_two",
  components: {},
};
</script>

<style scoped>
</style>