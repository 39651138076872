<template>
  <v-app>
    <allHeader />
    <div class="" >
      <div id="intro" class="bg-image shadow-2-strong" >
        <div class="container d-flex align-items-center justify-content-center text-center h-100">
          <div >
            <b-form inline style="margin-top: 120px; ">
            <label class="sr-only" for="inline-form-input-name">Produits</label>
            <b-form-input id="inline-form-input-name" class="mb-2 mr-sm-2 mb-sm-0"  size="lg" placeholder="Produits" v-model="recherche" ></b-form-input>
            <label class="sr-only" for="inline-form-input-username">Variétés</label>
            <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-input id="inline-form-input-username" size="lg" placeholder="Variétés" v-model="recherche_variete"></b-form-input>
            </b-input-group>
            <label class="sr-only" for="inline-form-input-username">Villes</label>
            <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-select v-model="selected" :options="options" size="lg"></b-form-select>
            </b-input-group>  
              <b-button variant="success" size="lg">Lancer la recherche</b-button>
            </b-form>
            <h3 style="margin-top: 20px; color: white;"><strong> Le marché virtuel des grandes opportunités</strong></h3>
            <note_voice/>
            <audio/>
          </div>
        </div>
     </div>
    </div>
    <service_two />
    <section class="gray-boxed-wrapper home-one__boxed" style="margin-top: -160px" >
      <img src="" alt="" class="home-one__boxed-bg" />
      <section class="service-two">
        <div class="container">
          <div class="block-title text-center">
           <h3>POUR VOUS</h3>
            <p>...</p>
          </div>
		    <div class="section">
			<div class="container">
				<div class="row">
					<div class="col-md-3 col-xs-6" v-for="produit in filterProducts" :key="produit.id">
						<div class="product" style="border-radius: 10px 10px 0px 0px">
							<div class="product-img">
								<img :src="produit.images" alt=""  width="290" height="205" style="border-radius: 10px 10px 0px 0px">
								<div class="product-label">
								</div>
							</div>
							<div class="product-body">
								<h3 class="product-name" style="font-family: Segoe UI;  font-size: 20px">{{ produit.Nom }}</h3>
								<p class="product-category" style="font-family: Segoe UI;">Quantité : {{ produit.quantite_produit }}</p>
                <h4 class="product-price" style="font-family: Segoe UI;">Prix : {{  Math.trunc(produit.prix_produit).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Fcfa</h4>
							  <div class="product-rating">
								</div>
                  <div class="product-btns">
										<button class="add-to-wishlist"><i class="fa fa-thumbs-up"></i><span class="tooltipp" >J'aime</span></button>
										<!-- <button class="add-to-compare"><i class="fas fa-comment-alt"></i><span class="tooltipp">Commenter</span></button> -->
										<button class="quick-view"><i class="fas fa-share"></i><span class="tooltipp">Partager</span></button>
										<button class="add-to-wishlist"><i class="fa fa-heart"></i><span class="tooltipp">Ajouter aux favoris</span></button>
									</div>
							  </div>
							<div class="add-to-cart">
								<button class="add-to-cart-btn" @click="redirection(produit.id)"><i class="fa fa-shopping-cart"></i>VOIR</button>
							</div>
						</div>
					</div>
				</div>
			</div>
      </div>
        <Paginator :rows="12" :totalRecords="listeProduits && listeProduits.donnees ? listeProduits.donnees.length : 0 " @page="onPage($event)" style="margin-top: 20px;"></Paginator>
      </div>
    </section>
      <div class="blog-home__slogan">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <div class="blog-home__slogan-main">
                <i class="agrikon-icon-farm"></i>
                <div class="blog-home__slogan-content">
                  <h3>Des produits qui émanent directement des champs</h3>
                  <p>
                    Ici, vous êtes en contact direct avec le vendeur ou plutôt
                    l'agriculteur. Pas d'intermédiaire pour quoi que ce soit.
                    Votre transaction se passe entre votre fournisseur et vous.
                  </p>
                </div>
                <!-- /.blog-home__slogan-content -->
              </div>
              <!-- /.blog-home__slogan-main -->
            </div>
            <!-- /.col-lg-9 -->
            <div class="col-lg-3">
              <div class="blog-home__slogan-image">
                <img
                  src="../assets/images/resources/blog-cta-1.png"
                  alt=""
                />
              </div>
              <!-- /.blog-home__slogan-image -->
            </div>
            <!-- /.col-lg-3 -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container -->
      </div>
      <!-- /.blog-home__slogan -->
    </section>
    <recentePublication />
    <call_to_action__three />
    <div style="display: flex; justify-content: center; align-items: center">
      <v-col lg="8">
        <video-embed ref="youtube" src="https://www.youtube.com/watch?v=venXRekxIlY"></video-embed>
        <button class="btn btn-success" @click="change" style="margin: 10px;">Changer de vidéo</button>
      </v-col>
    </div>

    <allFooter />
  </v-app>
</template>

<script>
import allHeader from "../components/headFootPage/main-header.vue";
import service_one from "../components/homePage/service-one.vue";
import about_one from "../components/homePage/about-one";
import service_two from "../components/homePage/service-two";
import call_to_action__three from "../components/homePage/call-to-action__three";
import contact_two from "../components/homePage/contact-two";
import allFooter from "../components/headFootPage/main-footer";
import carouselPrime from "../components/carouselPrime.vue";
import recentePublication from "../components/recente-publication.vue";
import note_voice  from "../components/Note_voice.vue";
// import chat_boxVue from "../components/chat_box/chat_box.vue";
import { mapActions, mapGetters } from "vuex";
import audio from "../assets/audio/Recording_9.mp3"


export default {
  name: "exemplePage",

  components: {
    allHeader,
    service_one,
    about_one,
    service_two,
    call_to_action__three,
    contact_two,
    allFooter,
    carouselPrime,
	  recentePublication,
    note_voice,
    audio,
    // chat_boxVue,
  },
    data() {
      return {
        sheet: false,
        recherche: "",
        recherche_variete: "",
        nom_variete_produit: "",
        first: 0,
        liste_produit_tmp: [],
        selected: null,
        options: [
          { value: null, text: 'Villes' },
          { value: 'a', text: 'Abidjan' },
          { value: 'b', text: 'San Pedro' },
          { value: { C: '3PO' }, text: 'Aboisso' },
          // { value: 'd', text: 'This one is disabled', disabled: true }
        ]
      }
    },
    watch: {
    listeProduits() {
      if (this.listeProduits) {
        this.liste_produit_tmp = this.listeProduits.donnees.slice(0, 12);
      }
    },
  },

  computed: {
    ...mapGetters(["listeProduits"]),
    filterProducts() {
		  console.log(this.recherche);
      return this.liste_produit_tmp.filter(produit => produit.Nom?.toLowerCase().includes(this.recherche.toLowerCase()))
    }
  },

  mounted () {
   console.log('Will it play here?? lol');
   console.log('Play outside of');
},

  methods: {
    ...mapActions(["getProduits"]),
    init() {
      this.getProduits();
    },
    redirection(produit) {
      const path = `/detailduproduit/` + produit;
      if (this.$route.path !== path)
        this.$router.push({ path, params: { id: produit } });
    },
    onPage(event) {
      this.liste_produit_tmp = this.listeProduits.donnees.slice(
        event.first,
        event.first + 12
      );
    },
    change() {
      this.$refs.youtube.src = "https://www.youtube.com/watch?v=QONyptgH1q4";
    }
  },
};
</script>

<style scoped>
      #intro {
        background-image: url("../assets/images/cover.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 95vh;
      }
      /* Height for devices larger than 576px */
      @media (min-width: 992px) {
        #intro {
          margin-top: -58.59px;
        }
      }
</style>