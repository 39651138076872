import Api from "../../Api/index"
import HTTP from "../../Api/auth"


const formData = new FormData()
formData.append("grant_type", "client_credentials")
formData.append("client_id", 2)
formData.append("client_secret", process.env.VUE_APP_TOKEN)

const state = {
  client: [],
  userMsg: "",
  errors: "",
  errorConnexion: "",
  errorConnexion1: false,
  istokenSet: false,
  // state enregistrement vendeur-acheteur
  msgSuccessClient: "",
  msgErrorClient: null,
}

const getters = {
  client: state => state.client,
  userMsg: state => state.userMsg,
  errors: state => state.errors,
  successClient: state => state.successClient,
  errorConnexion: state => state.errorConnexion,
  istokenSet: state => state.istokenSet,
  errorConnexion1: state => state.errorConnexion1,
  // getters enregistrement vendeur-acheteur
  msgSuccessClient: state => state.msgSuccessClient,
  msgErrorClient: state => state.msgErrorClient,
}

const actions = {
  getToken({ commit }) {
    Api.post(process.env.VUE_APP_BASEURL + "oauth/token", formData)
      .then(response => {
        localStorage.setItem("token", response.data.access_token)
        commit("setToken", true)
        commit("setErors", "")
        console.log("get token success")
      })
      .catch(errors => {
        console.log(errors)
        commit("setToken", false)
        commit("setErors", errors.response.data)
        console.log("get token error")
      })
  },
  
  AuthenticationUsers({ commit }, data) {
    if (localStorage.getItem("token") === null) {
      Api.post(process.env.VUE_APP_APIURL + "oauth/token", formData)
        .then(response => {
          localStorage.setItem("token", response.data.access_token)
          commit("setErors", "")
          Api.post(process.env.VUE_APP_APIURL + "v1/utilisateurs/authentifier", data)
            .then(response => {
              commit("setUsers", response.data)
              console.log("response data if");
              console.log(response.data);
              const userdetails = {
                // user_id: response.data.oauth.utilisateur.id,
                nom: response.data.oauth.utilisateur.nom,
                prenom: response.data.oauth.utilisateur.prenom,
                contact: response.data.oauth.utilisateur.contact,
                localisation: response.data.oauth.utilisateur.localisation,
                email: response.data.oauth.utilisateur.email,
                profil: response.data.oauth.utilisateur.profil,
                // 
                photo: response.data.oauth.utilisateur.photo,
                photofond: response.data.oauth.utilisateur.photofond,
                status: response.data.oauth.utilisateur.status,
              }
              console.log(userdetails);
              localStorage.setItem("user", JSON.stringify(userdetails))
              const authentificationkey = {
                value: response.data.oauth.access_token,
                expiry: response.data.oauth.expires_at
              }
              localStorage.setItem("userauth", JSON.stringify(authentificationkey))
            })
            .catch(errors => {
              commit("setErors", errors.response.data.code_message)
              localStorage.setItem("user", "")
              localStorage.setItem("userauth", "")
            })
        })
        .catch(errors => {
          commit("setErors", errors.response.data.code_message)
        })
    } else {
      Api.post(process.env.VUE_APP_APIURL + "v1/utilisateurs/authentifier", data)
        .then(response => {
          commit("setUsers", response.data, "connexion reussi")
          console.log("response data else");
          console.log(response.data);
          const userdetails = {
            user_id: response.data.oauth.utilisateur.id,
            nom: response.data.oauth.utilisateur.nom,
            prenom: response.data.oauth.utilisateur.prenom,
            contact: response.data.oauth.utilisateur.contact,
            localisation: response.data.oauth.utilisateur.localisation,
            email: response.data.oauth.utilisateur.email,
            profil: response.data.oauth.utilisateur.profil,
            photo: response.data.oauth.utilisateur.photo,
            photofond: response.data.oauth.utilisateur.photofond,
            status: response.data.oauth.utilisateur.status,
          }
          console.log(response.data.oauth.utilisateur.profil);
          console.log(userdetails);
          localStorage.setItem("user", JSON.stringify(userdetails))
          const authkey = {
            value: response.data.oauth.access_token,
            expiry: response.data.oauth.expires_at
          }
          localStorage.setItem("userauth", JSON.stringify(authkey))
        })
        .catch(errors => {
          commit("setErrorConnexMsg", errors.response)
          commit("seterrorConnexion1", true)
          console.log(errors.response.data.code_message);
          console.log(errors.response.data);
          console.log(errors.response);
          console.log(errors);
          // localStorage.setItem("user", "")
          // localStorage.setItem("userauth", "")
        })
    }
  },
  // action enregistrement vendeur-acheteur
  addClient({ commit }, data) {
    Api.post(process.env.VUE_APP_AUTHURL + "utilisateurs", data)
      .then(() => {
        commit("setmsgSuccessClient", "Nouveau compte utilisateur créé.")
        console.log("Nouveau compte utilisateur créé.");
      })
      .catch(errors => {
        commit("setmsgErrorClient", errors)
        console.log(errors.response.data.erreurs);
        console.log(errors.response);
        console.log(errors.response.data.code_http);


        commit("setmsgSuccessClient", "")
      })
  },
  updateUser({ commit }, data) {
    console.log();
    HTTP.put(process.env.VUE_APP_AUTHURL + "utilisateurs", data)
      .then(() => {
        commit("setUserMsg", "Modification effectuée avec succès.")
        console.log("Modification effectuée avec succès.");
      })
      .catch(errors => {
        commit("setErors", errors)
      })
  }
}

const mutations = {
  setUsers: (state, users) => (state.client = users),
  setErors: (state, data) => (state.errors = data),
  setUserMsg: (state, usermsg) => (state.userMsg = usermsg),
  setErrorConnexMsg: (state, errorconnex) => (state.errorConnexion = errorconnex),
  setToken: (state, hasToken) => (state.istokenSet = hasToken),
  seterrorConnexion1: (state, haserrorConnexion1) => (state.errorConnexion1 = haserrorConnexion1),
  // mutations enregistrement vendeur-acheteur
  setmsgSuccessClient: (state, usermsg) => (state.msgSuccessClient = usermsg),
  setmsgErrorClient: (state, error) => (state.msgErrorClient = error),
}

export default {
  state,
  getters,
  actions,
  mutations
}