import Vue from "vue";
import Vuex from "vuex";
import ClientsModule from "../store/modules/client";
import produitsModule from "../store/modules/produits";
import commande from "../store/modules/achat"
import discussion from "../store/modules/discussion"
import commentaire from "../store/modules/commentaire"
import notifications from "../store/modules//notification"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},  
  mutations: {},
  actions: {},
  modules: {
    ClientsModule,
    produitsModule,
    commande,
    discussion,
    commentaire,
    notifications
  },
});