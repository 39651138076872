<template>
  <main>
		<!-- Section -->
		<div class="section">
			<!-- container -->
			<div class="container">
				<!-- row -->
				<div class="row">
					<div class="col-md-12">
						<div class="section-title text-center">
							<h3 class="title">récemment publié</h3>
						</div>
					</div>
					<!-- product -->
					<div class="col-md-3 col-xs-6" v-for="produit in liste_produit_tmp" :key="produit.id">
						<div class="product">
							<div class="product-img">
								<img :src="produit.images" alt=""  width="290" height="205">
								<div class="product-label">
								<span class="sale" style="font-family: Segoe UI;">Catégorie</span>
								<span class="new"></span>
								</div>
							</div>
							<div class="product-body">
								<h3 class="product-name" style="font-family: Segoe UI;  font-size: 20px"> {{ produit.Nom }}</h3>
								<p class="product-category" style="font-family: Segoe UI;">Quantité : {{ produit.quantite_produit }}</p>
                    			<h4 class="product-price" style="font-family: Segoe UI;">Prix : {{ Math.trunc(produit.prix_produit).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} Fcfa</h4>
								<div class="product-rating">
								</div>
                <div class="product-btns">
											<button class="add-to-wishlist"><i class="fa fa-thumbs-up"></i><span class="tooltipp" >J'aime</span></button>
											<!-- <button class="add-to-compare"><i class="fas fa-comment-alt"></i><span class="tooltipp">Commenter</span></button> -->
											<button class="quick-view"><i class="fas fa-share"></i><span class="tooltipp">Partager</span></button>
											<button class="add-to-wishlist"><i class="fa fa-heart"></i><span class="tooltipp">Ajouter aux favoris</span></button>
										</div>
							</div>
							<div class="add-to-cart">
								<button class="add-to-cart-btn" @click="redirection(produit.id)"><i class="fa fa-shopping-cart"></i>VOIR</button>
							</div>
						</div>
					</div>
					<!-- /product -->
				</div>
				<!-- /row -->
			</div>
			<!-- /container -->
		</div>
		<!-- /Section -->
  </main>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";

export default {
  name: "recentePublication",
  components: {
  },
  data() {
    return {
      first: 0,
      liste_produit_tmp: [],
    };
  },
  watch: {
    listeProduits() {
      if (this.listeProduits) {
        console.log(this.listeProduits.donnees);
        // let idProduits = this.listeProduits.donnees.libelle_produit;
        // console.log(idProduits);
        // console.log(this.listeProduits.donnees.length);
        // console.log("mes produits :::: ");
        // console.log(this.listeProduits.donnees);
        // console.log(
        //   "Taille de mes donnees : ",
        //   this.listeProduits.donnees.length
        // );
        this.liste_produit_tmp = this.listeProduits.donnees.slice(0, 4);
        // console.log("mes 10 premiers elt sont : ");
        // console.log(this.liste_produit_tmp);
      }
    },
    msgSuccessProduit() {
      if(this.msgSuccessProduit === "Le produit a bien été envoyé") {
        this.getUserProducts()
        console.log("Le produit a bien été envoyé");
    }
    },
  },

  computed: {
    ...mapGetters(["listeProduits", "msgSuccessProduit"]),
  },

  created() {
    this.getProduits();

    // console.log();
  },

  methods: {
    ...mapActions(["getProduits"]),
    redirection(produit) {
      const path = `/detailduproduit/` + produit;
      if (this.$route.path !== path)
        this.$router.push({ path, params: { id: produit } });
    },
    onPage(event) {
      // console.log("la valeur du onPage : ");
      // console.log(event);
      // console.log("valeur de row & first :");
      // console.log(event.first, event.rows);
      // console.log("calcule de page, ma nouvelle liste sera :");
      this.liste_produit_tmp = this.listeProduits.donnees.slice(
        event.first,
        event.first + 4
      );
      // console.log(this.liste_produit_tmp);
    },
  },
};
</script>

<style scoped>
@import "../assets/css/produit-detail.css";

</style>