<template>
  <main style="background-color: white;">
    <div class="marginHeader">
      <section class="project-details">
        <div class="container">
          <img
            src="../assets/images/table-with-vegetables-and-fruits.jpg"
            class="img-fluid"
            alt=""
          />
          <!-- <div class="projects-one project-page">
            <div class="container">
              <div class="block-title text-center">
                <h3 style="color: #ed836d;">Tout a été pensé pour vous.</h3>
                <h5>Le meilleur booster de circuit court ! 
                  Achetez ou vendez tous produits alimentaires solides et liquides sur denosterres.com car l'ère du circuit court est arrivée </h5>
              </div>
            </div>
          </div> -->
          <div class="bottom-content" style="margin-top: -0px">
            <div class="row">
              <div class="col-lg-2"></div>
               <div class="col-lg-8">
                <section style="margin-top: -60px">
                  <div class="container">
                    <div class="row justify-content-md-center">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div class="login-screen">
                            <div class="login-box">
                              <h2 class="text-center" style="color: #ed836d;">
                                Inscription Vendeur
                              </h2>
                              <div class="row gutters" >
                                
                                <a class="main-header__info-phone" v-for="error in erreurs" style="margin-bottom: 10px;">
                                   <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                                    <span class="main-header__info-phone-content">
                                        <span class="main-header__info-phone-title">{{error}}</span>
                                    </span><!-- /.main-header__info-phone-content -->
                                </a>
                                <br>
                                <br>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" :class="{ 'form-group--error': $v.nom.$error }">
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Prenom"
                                      v-model.trim="$v.prenom.$model"
                                    />
                                    <div class="error" v-if="!$v.prenom.required"> <em>Le prénom est requis</em></div>
                                  </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Nom"
                                      v-model.trim="$v.nom.$model"
                                    />
                                    <div class="error" v-if="!$v.nom.required"><em>Le nom est requis</em></div>
                                    <!-- <div class="error" v-if="!$v.name.minLength">Name must have at least {{$v.name.$params.minLength.min}} letters.</div> -->
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <input
                                      type="email"
                                      class="form-control"
                                      placeholder="Email"
                                      v-model.trim="$v.email.$model"
                                    />
                                    <div class="error" v-if="!$v.email.required"><em>Le email est requis</em></div>
                                    <!-- <div class="error" v-if="!$v.name.minLength">Name must have at least {{$v.name.$params.minLength.min}} letters.</div> -->
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <input
                                      type="password"
                                      class="form-control"
                                      placeholder="Mots de passe"
                                      v-model.trim="$v.mot_de_passe.$model"
                                    />
                                    <div class="error" v-if="!$v.mot_de_passe.required"><em>Le mot de passe est requis</em></div>
                                    <div class="error" v-if="!$v.mot_de_passe.minLength"><em>Le mot de passe doit être égale ou superieur à {{$v.mot_de_passe.$params.minLength.min}} caractères.</em></div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <input
                                      type="password"
                                      class="form-control"
                                      placeholder="Confirmer mots de passe"
                                      v-model.trim="$v.mot_de_passe_confirmation.$model"
                                    />
                                    <div class="error" v-if="!$v.mot_de_passe_confirmation.sameAsPassword">Les mots de passe doivent être identique</div>
                                    <!-- <div class="error" v-if="!$v.mot_de_passe_confirmation.required"><em>Le mot de passe est requis</em></div>
                                    <div class="error" v-if="!$v.mot_de_passe_confirmation.minLength"><em>Le mot de passe doit être égale ou superieur à {{$v.mot_de_passe_confirmation.$params.minLength.min}} caractères.</em></div> -->
                                  </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Localité"
                                      v-model.trim="$v.ville.$model"
                                    />
                                    <div class="error" v-if="!$v.ville.required"><em>La Localité est requise</em></div>
                                    <!-- <div class="error" v-if="!$v.name.minLength">Name must have at least {{$v.name.$params.minLength.min}} letters.</div> -->
                                  </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="contact"
                                      v-model.trim="$v.contact.$model"
                                    />
                                    <div class="error" v-if="!$v.contact.required"><em>Le contact est requis</em></div>
                                    <div class="error" v-if="!$v.contact.minLength"><em>Le numero de téléphone est de {{$v.contact.$params.minLength.min}} chiffre.</em></div>
                                  </div>
                                </div>

                            
                                <div class="col-12">
                                  <div class="form-group">
                                      <h5 class="text-center">Veuillez sélectionné votre position sur la carte</h5>
                                      <div >
                                      <!-----------------------------------------------------------------------------------------------------------> 
                                      <l-map
                                            :zoom="zoom"
                                            :center="center"
                                            style="height: 400px; width: 100%"
                                            @click="addMarker"
                                          >
                                            <!-- <l-tile-layer
                                              :url="url"
                                              :attribution="attribution"
                                            /> -->
                                            <l-control-layers
                                            :position="layersPosition"
                                            :collapsed="false"
                                            :sort-layers="true"
                                          />
                                            <l-tile-layer
                                            v-for="tileProvider in tileProviders"
                                            :key="tileProvider.name"
                                            :name="tileProvider.name"
                                            :visible="tileProvider.visible"
                                            :url="tileProvider.url"
                                            :attribution="tileProvider.attribution"
                                            :token="token"
                                            layer-type="base"
                                          />
                                            <!-- <l-control
                                              :position="'bottomleft'"
                                              class="custom-control-watermark"
                                            >
                                              Vue2Leaflet Watermark Control
                                            </l-control> -->
                                            <l-marker v-for="marker, index in markers" :lat-lng="marker" :key="index" @click="removeMarker(index)"></l-marker>
                                            <l-control-scale />
                                            
                                          </l-map>
                                        </div>
                                  </div>
                                </div>

                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                  <div class="form-group">
                                    <select class="form-select" v-model="status" @change="onChange('x')" aria-label="Default select example">
                                      <option selected>Selectionner votre status</option>
                                      <option value="MEMBRE D'ASSOCIATION">MEMBRE D'ASSOCIATION</option>
                                      <option value="INDIVIDUEL">INDIVIDUEL</option>
                                    </select>
                                    <!-- <div class="error" v-if="!$v.ville.required"><em>Le ville est requis</em></div> -->
                                    <!-- <div class="error" v-if="!$v.name.minLength">Name must have at least {{$v.name.$params.minLength.min}} letters.</div> -->
                                  </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Profil: VENDEUR"
                                      disabled
                                    />
                                    <!-- <div class="error" v-if="!$v.contact.required" ><em>Le contact est requis</em></div>
                                    <div class="error" v-if="!$v.contact.minLength"><em>Le numero de téléphone est de {{$v.contact.$params.minLength.min}} chiffre.</em></div> -->
                                  </div>
                                </div>
                                <!--  -->
                                <div class="col-12">
                                 <h5>Insérer une photo de profil</h5>
                                  <div class="form-group" >
                                    <input
                                      type="file"
                                      name="photoUser"
                                      id="photoUser"
                                      ref="photoUser"
                                      @change="onFileCharge('photoUser')"
                                    />
                                  <img
                                    :src="this.image_url"
                                    class=" profile-avatar"
                                    alt=" Photo de profil"
                                    style="margin-top: 0px"
                                  />
                                    <!-- <input
                                      type="password"
                                      class="form-control"
                                      placeholder="Mots de passe"
                                      v-model.trim="$v.mot_de_passe.$model"
                                    />
                                    <div class="error" v-if="!$v.mot_de_passe.required"><em>Le mot de passe est requis</em></div>
                                    <div class="error" v-if="!$v.mot_de_passe.minLength"><em>Le mot de passe doit être égale ou superieur à {{$v.mot_de_passe.$params.minLength.min}} caractères.</em></div> -->
                                  </div>
                                </div>
                                <div class="col-12">
                                <h5>Insérer une bannière</h5>

                                  <div class="form-group" >
                                    <input
                                      type="file"
                                      name="banniere"
                                      id="banniere"
                                      ref="banniere"
                                      @change="onFileChargebanniere('banniere')"
                                    />
                                  <img
                                    :src="this.banniere_url"
                                    class="img-circle profile-avatar"
                                    alt=" Bannier"
                                    style="margin-top: 0px"
                                  />
                                    <!-- <input
                                      type="password"
                                      class="form-control"
                                      placeholder="Mots de passe"
                                      v-model.trim="$v.mot_de_passe.$model"
                                    />
                                    <div class="error" v-if="!$v.mot_de_passe.required"><em>Le mot de passe est requis</em></div>
                                    <div class="error" v-if="!$v.mot_de_passe.minLength"><em>Le mot de passe doit être égale ou superieur à {{$v.mot_de_passe.$params.minLength.min}} caractères.</em></div> -->
                                  </div>
                                </div>
                              </div>
                              <div class="actions clearfix">
                                <button
                                  type="button"
                                  @click="submit"
                                  :disabled="submitStatus === 'PENDING'"
                                  class="btn btn-primary btn-block"
                                >
                                  Créer
                                </button>
                                <!-- <p class="typo__p" v-if="submitStatus === 'OK'">Thanks for your submission!</p> -->
                                <p class="typo__p" v-if="submitStatus === 'ERROR'">Merci de bien remplir le formulaire</p>
                                <p class="typo__p" v-if="submitStatus === 'PENDING'">Enregistrement en cours...</p>
                              </div>
                              <div class="or">
                                <span>Ou inscrivez vous en tant que </span>
                              </div>
                              <div class="row gutters">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <router-link to="/inscription/acheteur"
                                      ><button type="submit" class="btn btn-tw btn-block">
                                        Acheteur
                                      </button></router-link
                                    >
                                  </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                  <!-- <button
                                    type="submit"
                                    class="btn btn-tw btn-block"
                                  ></button> -->
                                </div>
                              </div>
                              <div class="or" style="margin-top: 10px;">
                                <span>Ou inscrivez avec </span>
                              </div>
                              <div class="row gutters">
                                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                              <!--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
                                      <ins_fb_vendeur />
                                  </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                  <div class="product-details1">
                                    <div class="add-to-cart1">
                                                    <button class="add-to-cart-btn1" @click="googleOauth()"><b-icon icon="google"></b-icon> Google</button>
                                                </div>
                                              </div>
                                </div>
                              </div>
                                <a href="#" class="additional-link">Vous avez deja un compte ?<router-link to="/connexion"><span>Se connecter</span></router-link></a>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </section>
                </div>
                <div class="col-lg-2"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import ins_fb_vendeur from '../components/social_login/facebook_login/ins_fb_vendeur.vue';
//   import denosterresMap from '../components/map/map.vue'
import { latLng } from "leaflet";
import { LMap, LTileLayer, LControl, LControlScale, LControlLayers} from "vue2-leaflet";
const tileProviders = [
  {
    name: 'OpenStreetMap',
    visible: true,
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  {
    name: 'OpenTopoMap',
    visible: false,
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution:
      'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
  },
];


export default {
  name: "EnregAcheteur",
  components: {
    ins_fb_vendeur,
      //   denosterresMap,
  LMap,
  LTileLayer,
  LControl,
  LControlScale,
  LControlLayers
  },
  props: {},
  data() {
    return {
      layersPosition: 'topright',
      token: 'your token if using mapbox',
      tileProviders: tileProviders,
      zoomPosition: 'topleft',
      zoom: 6,
      center: latLng(6.806444048123682, -5.284423828125),
      //   url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      //   attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markers: [
        latLng(6.806444048123682, -5.284423828125),
      ],

      nom: "",
      prenom: "",
      contact: "",
      ville: "",
      email: "",
      mot_de_passe: "",
      mot_de_passe_confirmation: "",
      profil: "VENDEUR",
      submitStatus: null,
      erreurs: [],
      status: "INDIVIDUEL",
      erreurs_fb: "",
      image_url: "",
      banniere_url: "",
      uploadfile: "photoUser",
      inputFiles: { photoUser: "" },
    }
  },
  validations: {
    nom: {
      required,
      // minLength: minLength(4)
    },
    prenom: {
      required,
      // minLength: minLength(4)
    },
    email: {
      required,
      // minLength: minLength(4)
    },
    mot_de_passe: {
      required,
      minLength: minLength(8)
    },
    mot_de_passe_confirmation: {
      sameAsPassword: sameAs('mot_de_passe')
    },
    ville: {
      required,
      // minLength: minLength(4)
    },
    contact: {
      required,
      minLength: minLength(10)
    },
  },

  watch: {
    msgSuccessClient() {
      if (this.msgSuccessClient === "Nouveau compte utilisateur créé.") {
        console.log(this.msgSuccessClient);
        this.$router.push({ name: "Connexion" });
        this.toastSuccess()
      }
    },
    msgErrorClient() {
      if (this.msgErrorClient) {
        console.log(this.msgErrorClient);
        console.log(this.msgErrorClient.response.data.erreurs);
        // for (const valeur of this.msgErrorClient.response.data.erreurs) {
          // console.log(valeur);
          this.erreurs = this.msgErrorClient.response.data.erreurs;
          this.erreurs_fb = this.msgErrorClient.response.data.code_message;
          console.log(this.erreurs_fb);
          if (this.erreurs_fb === "ERR_EMAIL_UTILISE") {
            this.erreurs_fb_msg()
          }
          console.log(this.erreurs);
        // }
      }
    },
    filesPictureUser() {
      if (this.filesPictureUser.code_http === 200 || this.filesPictureUser.code_http === 201) {
        this.msg_Success_Photo_User();
        let imageUser = process.env.VUE_APP_UPLOAD + this.filesPictureUser["url"] ;
        console.log(imageUser);
        localStorage.setItem('image', imageUser);
        this.image_url  = localStorage.getItem('image')
      }
    },
    filesbanniere() {
      if (this.filesbanniere.code_http === 200 || this.filesbanniere.code_http === 201) {
        this.msg_Success_Video_User();
        this.banniere_url = process.env.VUE_APP_UPLOAD + this.filesbanniere["url"] ;
        console.log(this.banniere_url);
      }
    },
  },

  computed: {
    ...mapGetters(["msgSuccessClient", "msgErrorClient", "filesPictureUser", "filesbanniere"]),
  },

  methods: {
    ...mapActions(["addClient", "saveUserPicture", "saveUserbanniere"]),

    onFileCharge(filename) {
      this.inputFiles[filename] = this.$refs[filename].files[0].name;
      this.saveUserPicture(this.$refs[filename].files[0]);
    },
    onFileChargebanniere(filename) {
      this.inputFiles[filename] = this.$refs[filename].files[0].name;
      this.saveUserbanniere(this.$refs[filename].files[0]);
    },
// Toast une fois la photo chargé
    msg_Success_Photo_User() {
       this.$toast.add({severity:'success', summary: 'Succès', detail:'Votre photo de profil a bien été chargé', life: 3000});
    },
// Toast une fois la banière chargé
    msg_Success_Video_User() {
      this.$toast.add({severity:'success', summary: 'Succès', detail:'Votre vidé a bien été chargé', life: 3000});
    },
    erreurs_fb_msg() {
      this.$toast.add({severity:'error', summary: 'Erreur', detail:'le mail est déja utilisé ', life: 10000});
    },
    submit() {
      console.log('submit!')
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        setTimeout(() => {
          this.submitStatus = 'OK'
        }, 500)
      let newUser = {
        nom: this.nom,
        prenom: this.prenom,
        contact: this.contact,
      //   ville: this.ville,
        email: this.email,
        mot_de_passe: this.mot_de_passe,
        mot_de_passe_confirmation: this.mot_de_passe_confirmation,
        profil: this.profil,
        photo: this.image_url,
        photofond: this.banniere_url,
        status: this.status,
        // nom_adresse: this.nom_adresse,
        nom_adresse: "Côte d'Ivoire",
        adresse: this.ville,
        latitude: this.latitude,
        longitude: this.longitude,
        // provider_id: "oirffikf"
      };
      console.log(newUser);
      this.addClient(newUser);
      }
    },
    /////////////////////////////////// TOAST
    toastSuccess() {
       this.$toast.add({severity:'success', summary: 'Succès', detail:'Vous avez bien été enregistré, veuillez à présent vous connecter.', life: 5000});
    },
    ////////////////////////////////// Oauth_google-facebook
    facebookOauth() {
      console.log("Facebook oauth a été clické");
    },
    googleOauth() {
      console.log("Google oauth a été clické");
    },
    onChange(evenement){
        console.log(this.status); //On y accède de n'importe où dans le framework
    },

      //<!----------------------------------------------------------------------------------------------------------->
removeMarker(index) {
  this.markers.splice(index, 1);
},
addMarker(event) {
    this.markers.pop()
 this.markers.push(event.latlng);
 
  console.log(event);
  console.log(event.latlng);
  console.log(event.latlng.lat);
  console.log(event.latlng.lng);
  this.latitude = event.latlng.lat;
  this.longitude = event.latlng.lng;
}
  }
}
</script>


<style scoped>
/* @import "../assets/css/css.All.Element/cssConnexion.css"; */

/* ------------------ button facebook and google -------------------- */
  .product .add-to-cart1 .add-to-cart-btn1 {
    position: relative;
    border: 2px solid transparent;
    height: 40px;
    padding: 0 30px;
    background-color: #ef233c;
    color: #FFF;
    /* text-transform: uppercase; */
    font-weight: 700;
    border-radius: 40px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
  }
  
  .product .add-to-cart1 .add-to-cart-btn1>i {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    line-height: 38px;
    color: #ffffff;
    opacity: 0;
    visibility: hidden;
  }
  
  .product .add-to-cart1 .add-to-cart-btn1:hover {
    background-color: #FFF;
    color: #ffffff;
    border-color: #ffffff;
    padding: 0px 30px 0px 50px;
  }
  
  .product .add-to-cart1 .add-to-cart-btn1:hover>i {
    opacity: 1;
    visibility: visible;
  }

  .product-details1 .add-to-cart1 .add-to-cart-btn1 {
    position: relative;
    border: 2px solid transparent;
    height: 40px;
    padding: 0 30px;
    background-color: #7daf5e;
    color: #FFF;
    /* text-transform: uppercase; */
    font-weight: 700;
    border-radius: 40px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
  }
  
  .product-details1 .add-to-cart1 .add-to-cart-btn1>i {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    line-height: 38px;
    color: #7daf5e;
    opacity: 0;
    visibility: hidden;
  }
  
  .product-details1 .add-to-cart1 .add-to-cart-btn1:hover {
    background-color: #FFF;
    color: #7daf5e;
    border-color: #7daf5e;
    padding: 0px 30px 0px 50px;
  }
  
  .product-details1 .add-to-cart1 .add-to-cart-btn1:hover>i {
    opacity: 1;
    visibility: visible;
  }
.marginHeader {
  margin-top: 5px;
}
.btn-tw {
  background: #7daf5e;
  color: #ffffff;
}
.btn-tw:hover {
  background: #7daf5e;
  color: #ffffff;
}
.btn-tw:focus {
  box-shadow: 0 0 0 1px #7daf5e;
}

/* .btn-tw:not(:disabled):not(.disabled).active,
.btn-tw:not(:disabled):not(.disabled):active, */
.login-screen {
  position: relative;
  box-shadow: 0px 6px 4px #ed836d;
  background: #ffffff;
  margin: 60px auto;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.login-screen .login-logo {
  margin: 20px 0 30px 0;
  display: block;
}

.login-screen .login-logo > img {
  margin: 0;
  max-height: 36px;
}

.login-screen .login-box {
  padding: 30px 30px;
}

.login-screen .login-box h5 {
  margin: 0 0 20px 0;
  color: #ed836d;
  text-align: center;
}

.login-screen .actions {
  display: block;
  margin-bottom: 20px;
  text-align: center;
}

.login-screen .actions a {
  color: #ed836d;
  text-decoration: none;
  margin-top: 12px;
  display: block;
  font-size: 0.8rem;
}

.login-screen .actions a:hover {
  text-decoration: underline;
}

.login-screen .actions .btn {
  padding: 8px 25px;
  text-transform: uppercase;
}

.login-screen a.additional-link {
  text-decoration: none;
  margin: 30px auto 0 auto;
  text-align: center;
  display: block;
}

.login-screen a.additional-link span {
  text-decoration: underline;
  /* color: #ed836d; */
}

.login-screen p.info {
  padding: 0;
  margin: 0 0 20px 0;
  line-height: 150%;
  color: #8796af;
  text-align: center;
}

/*************** 13 B. OR | Line between two blocks ***************/
.or {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}
.or::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 15%;
  width: 70%;
  height: 1px;
  background-color: #e6ecf3;
}
.or span {
  position: relative;
  background-color: #ffffff;
  padding: 0 10px;
  z-index: 10;
}

/*************** 17 C. Buttons ***************/
.btn {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border: 0;
  padding: 0.375rem 1rem;
}
.btn .icon {
  font-size: 1.4rem;
  vertical-align: middle;
  margin-top: -4px;
  display: inline-block;
}
.btn.focus,
.btn:focus {
  color: #ffffff;
}

.btn-primary {
  background: #ed836d;
  color: #ffffff;
}
.btn-primary:hover {
  background: #ed836d;
  color: #ffffff;
}
.btn-primary:focus {
  box-shadow: 0 0 0 1px #fcfdff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: #ed836d;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background: #ed836d;
}

.btn.disabled,
.btn:disabled {
  pointer-events: none;
}
</style>