import HTTP from "../../Api/auth"
import Api from "../../Api/index"

const state = {
    // post_COMMENTAIREs
    COMMENTAIRE: [],
    COMMENTAIRE_ERROR: "",
    // get_COMMENTAIREs
    COMMENTAIRE_RECEP: [],
    COMMENTAIRE_RECEP_ERROR: "",
    // del_COMMENTAIREs
    COMMENTAIRE_DEL: [],
    COMMENTAIRE_DEL_ERROR: "",
    DELSET: false,
}

const getters = {
    // post_COMMENTAIRE
    COMMENTAIRE: state => state.COMMENTAIRE,
    COMMENTAIRE_ERROR: state => state.COMMENTAIRE_ERROR,    
    // post_COMMENTAIRE
    COMMENTAIRE_RECEP: state => state.COMMENTAIRE_RECEP,
    COMMENTAIRE_RECEP_ERROR: state => state.COMMENTAIRE_RECEP_ERROR,
    // post_COMMENTAIRE
    COMMENTAIRE_DEL: state => state.COMMENTAIRE_DEL,
    COMMENTAIRE_DEL_ERROR: state => state.COMMENTAIRE_DEL_ERROR,
    DELSET: state => state.DELSET,
}

const actions = {
    POST_COMMENTAIRE({ commit }, data) {
        HTTP.post(process.env.VUE_APP_AUTHURL + "commentaires", data)
            .then(response => {
                commit("SET_COMMENTAIRE", response.data)
                console.log("succes");
                   console.log(response.data);
            })
            .catch((error) => {
                commit("SET_COMMENTAIRE_ERROR", error)
                console.log("erreur");
            })
    },
    GET_USER_COMMENTAIRE({ commit }, id) {
        HTTP.get(process.env.VUE_APP_AUTHURL + `commentaires/` + id)
            .then(response => {
                commit("SET_RECEP_COMMENTAIRE", response.data)
                console.log("succes get commentaires");
                console.log(response.data);
            })
            .catch((error) => {
                commit("SET_RECEP_COMMENTAIRE_ERROR", error)
                console.log("erreur get COMMENTAIRE");
            })
    },
    DEL_COMMENTAIRE({ commit }, id) {
        HTTP.delete(process.env.VUE_APP_AUTHURL + `commentaires/` + id)
        .then(() => { commit("SET_DEL_COMMENTAIRE", 'Le commentaire a bien été supprime')
            commit("SETDEL", true)
            console.log("Le produit a bien été supprimé");
        }).catch((error) => {
            commit("SET_DEL_COMMENTAIRE_ERROR", "error")
            commit("SETDEL", false)
        })
    },
}

const mutations = {
    // post_COMMENTAIRE
    SET_COMMENTAIRE: (state, varList) => (state.COMMENTAIRE = varList),
    SET_COMMENTAIRE_ERROR: (state, varListE) => (state.COMMENTAIRE_ERROR = varListE),
    // post_COMMENTAIRE
    SET_RECEP_COMMENTAIRE: (state, rcp) => (state.COMMENTAIRE_RECEP = rcp),
    SET_RECEP_COMMENTAIRE_ERROR: (state, rcp) => (state.COMMENTAIRE_RECEP_ERROR = rcp),

    // del_COMMENTAIRE
    SET_DEL_COMMENTAIRE: (state, del) => (state.COMMENTAIRE_DEL = del),
    SET_DEL_COMMENTAIRE_ERROR: (state, del) => (state.COMMENTAIRE_DEL_ERROR = del),
    SETDEL: (state, sup) => (state.DELSET = sup),
}


export default {
    state,
    mutations,
    getters,
    actions
}