<template>
  <main>
    <section class="service-one" style="margin-top: 110px">
      <div class="container">
        <div class="row">
          <div
            class="col-md-12 col-lg-4 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div class="service-one__box">
              <img src="../../assets/images/services/service-1-1.jpg" alt="" />
              <div class="service-one__box-content">
                <h3>
                  <router-link to="/services">Produits vivriers</router-link>
                </h3>
              </div>
              <!-- /.service-one__box-content -->
            </div>
            <!-- /.service-one__box -->
          </div>
          <!-- /.col-md-12 col-lg-4 -->
          <div
            class="col-md-12 col-lg-4 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div class="service-one__box">
              <img src="../../assets/images/services/service-1-2.jpg" alt="" />
              <div class="service-one__box-content">
                <h3><router-link to="/services">Céréale</router-link></h3>
              </div>
              <!-- /.service-one__box-content -->
            </div>
            <!-- /.service-one__box -->
          </div>
          <!-- /.col-md-12 col-lg-4 -->
          <div
            class="col-md-12 col-lg-4 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div class="service-one__box">
              <img src="../../assets/images/services/service-1-3.jpg" alt="" />
              <div class="service-one__box-content">
                <h3>
                  <router-link to="/services"
                    >Produits d'exportation</router-link
                  >
                </h3>
              </div>
              <!-- /.service-one__box-content -->
            </div>
            <!-- /.service-one__box -->
          </div>
          <!-- /.col-md-12 col-lg-4 -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>
  </main>
</template>

<script>
export default {
  name: "service_one",
  components: {},
};
</script>

<style scoped>
.AE {
  background-image: url("../../assets/images/backgrounds/page-header-bg-1-1.jpg");
}
</style>