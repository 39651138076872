<template>
  <div>
    <div class="col-lg-12" >
      <div class="card bg-primary text-white rounded-3">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <h5 class="mb-0">Info :</h5>
            <h5 class="mb-0" style="color: white"><strong> modifier la commande ID : {{ commande_id }} </strong></h5>
            <!-- <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp"
              class="img-fluid rounded-3" style="width: 45px;" alt="Avatar"> -->
          </div>
          <form class="mt-4">
            <div class="row mb-4">
              <div class="col-md-6">
                <div class="form-outline form-white">
                  <label class="form-label" for="typeExp">Quantité</label>
                  <input type="text" v-model="quantié_achat" id="typeExp" class="form-control form-control-lg" placeholder="" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-outline form-white">
                  <b-form-group id="input-group-2" label="Unité de mésure :" label-for="input-2" >
                      <select class="form-select" aria-label="Default select example">
                        <option value="Tonne(t)" disabled>  Tonne (T) </option>
                        <option value="Kilogramme(Kg)" disabled>  Kilogramme (Kg) </option>
                        <option value="Gramme (g)" disabled>  Gramme (g) </option>
                        <option value="miligramme (Mg)" disabled>  miligramme (Mg) </option>
                        <option value="Metre_Cube" disabled>  Metre Cube </option>
                        <option value="Litre(l)" disabled>  Litre (L) </option>
                        <option value="Millilitre (Ml)" disabled>  Millilitre (Ml) </option>
                      </select>
                  </b-form-group>
                </div>
              </div>
            </div>
          </form>
          <div class="d-flex justify-content-between">
            <p class="mb-2">Prix du produit :</p>
            <p class="mb-2">000.00 Fcfa</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="mb-2">Transport :</p>
            <p class="mb-2">000.00 Fcfa</p>
          </div>
          <div class="d-flex justify-content-between mb-4">
            <p class="mb-2">Total :</p>
            <p class="mb-2">000.00 Fcafa</p>
          </div>
          <button type="button" class="btn btn-info btn-block btn-lg" @click="update_commande()" disabled>
            <div class="d-flex justify-content-between">
              <span></span>
              <span>Modifier la commande <i class="fas fa-long-arrow-alt-right ms-2"></i></span>
            </div>
          </button>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  
  export default {
    name: "commande_upd",
    data() {
      return {
        quantié_achat: "",
        commande_id: "",
        liste_commande_temp: "",
        liste_commande: [],
        quantié_achats: [],
      };
    },
  
    watch: {
      COMMANDE_LIST() {
        if (this.COMMANDE_LIST) {
          console.log(this.COMMANDE_LIST);
          console.log(this.COMMANDE_LIST.original.object);
          this.liste_commande = this.COMMANDE_LIST.original.object;
          this.liste_commande_temp = this.liste_commande.filter(element => element.id == this.commande_id)
          console.log(this.liste_commande_temp);
          this.quantié_achats = this.liste_commande_temp.quantite_acheter;
          console.log(this.liste_commande_temp.quantite_acheter);
        }
      },
    },
    computed: {
      ...mapGetters(["COMMANDE_UPDATE", "COMMANDE_LIST"]),
    },
    created() {
      // this.COMMANDE_LIST()
      this.GET_USER_COMMANDE();

    },
    mounted() {
      this.commande_id = this.$route.params.id
      console.log(this.commande_id);
    },
  
    methods: {
      ...mapActions(["PUT_USER_COMMANDE", "GET_USER_COMMANDE"]),
      update_commande() {
          let commande_a_modifier = {
		          quantite_acheter: this.quantié_achat,
		          utilisateur_id: this.id_user,
		          id_produit: this.id_to_send,
          };
      console.log(commande_a_modifier);
      // this.PUT_USER_COMMANDE(commande_a_modifier);
      }
      
    },
  };
  </script>

<style scoped>
@import "../assets/css/profil_css.css";

.marginHeader {
  margin-top: 80px;
}
.marg {
  margin-left: 60px;
}
</style>