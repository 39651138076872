import HTTP from "./../../Api/auth"
import Api from "./../../Api/index"

const state = {
    // post_commande
    COMMANDE: [],
    COMMANDE_ERROR: "",
    // get_commande
    COMMANDE_LIST: [],
    COMMANDE_LIST_ERROR: "",
    // put_commande
    // get_commande
    COMMANDE_UPDATE: [],
    COMMANDE_UPDATE_ERROR: "",

    DELETE_CMDE: false,
    DELETE_COMMANDE_ERROR: "",
    DELETE_COMMANDE: "",
}

const getters = {
    // post_commande
    COMMANDE: state => state.COMMANDE,
    COMMANDE_ERROR: state => state.COMMANDE_ERROR,
    // get_commande
    COMMANDE_LIST: state => state.COMMANDE_LIST,
    COMMANDE_LIST_ERROR: state => state.COMMANDE_LIST_ERROR,
    // put_commande
    COMMANDE_UPDATE: state => state.COMMANDE_UPDATE,
    COMMANDE_UPDATE_ERROR: state => state.COMMANDE_UPDATE_ERROR,

    DELETE_CMDE: state => state.DELETE_CMDE,
    DELETE_COMMANDE_ERROR: state => state.DELETE_COMMANDE_ERROR,
    DELETE_COMMANDE: state => state.DELETE_COMMANDE,

}

const actions = {
    POST_COMMANDE({ commit }, data) {
        HTTP.post(process.env.VUE_APP_AUTHURL + "acheters", data)
            .then(response => {
                commit("SET_COMMANDE", "Le produit a bien été envoyé")
                console.log("succes");
                   console.log(response.data);
            })
            .catch((error) => {
                commit("SET_COMMANDE_ERROR", error)
                console.log("erreur");
            })
    },
    GET_USER_COMMANDE({ commit }) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "acheters")
            .then(response => {
                commit("SET_COMMANDE_LIST", response.data)
                console.log("succes get commande");
                console.log(response.data);
            })
            .catch((error) => {
                commit("SET_COMMANDE_LIST_ERROR", error)
                console.log("erreur get commande");
            })
    },
    PUT_USER_COMMANDE({ commit }, data) {
        HTTP.put(process.env.VUE_APP_AUTHURL + "acheters", data)
            .then(response => {
                commit("SET_COMMANDE_UPDATE", "Modification de la commande effectuée avec succès.")
                console.log("modification effectué");
            })
            .catch((error) => {
                commit("SET_COMMANDE_UPDATE_ERROR", error.response.data.code_message)
                console.log("erreur de modification commande");
            })
    },
    DELETE_COMDES({ commit }, id) {
        HTTP.delete(process.env.VUE_APP_AUTHURL + `produits/` + id).then(() => {
            commit("SET_DELETE_COMMANDE", 'Le produit a bien été supprime')
            commit("SET_DELETE_CMDE", true)
            console.log("Le produit a bien été supprimé");
        }).catch((error) => {
            commit("SET_DELETE_COMMANDE_ERROR", error)
            console.log(error);
            commit("SET_DELETE_CMDE", false)
        })
    },
}

const mutations = {
    // post_commande
    SET_COMMANDE: (state, hasVarList) => (state.COMMANDE = hasVarList),
    SET_COMMANDE_ERROR: (state, hasVarListE) => (state.COMMANDE_ERROR = hasVarListE),
    // get_commande
    SET_COMMANDE_LIST: (state, hasVarList) => (state.COMMANDE_LIST = hasVarList),
    SET_COMMANDE_LIST_ERROR: (state, hasVarListE) => (state.COMMANDE_LIST_ERROR = hasVarListE),
    // put_commande
    SET_COMMANDE_UPDATE: (state, hasVarList) => (state.COMMANDE_UPDATE = hasVarList),
    SET_COMMANDE_UPDATE_ERROR: (state, hasVarListE) => (state.COMMANDE_UPDATE_ERROR = hasVarListE),
    // delete
    SET_DELETE_COMMANDE_ERROR: (state, error) => (state.DELETE_COMMANDE_ERROR = error),
    SET_DELETE_CMDE: (state, hasDel) => (state.DELETE_CMDE = hasDel),
    SET_DELETE_COMMANDE: (state, deleteproduitts) => (state.DELETE_COMMANDE = deleteproduitts),
}


export default {
    state,
    mutations,
    getters,
    actions
}