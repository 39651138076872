<template>
  <div id="app">
    <div v-if="istokenSet">
       <router-view />
    </div>
    <div id="intro" v-if="!istokenSet">
      <!-- <img src="../src/assets/logo.png" alt=""> -->
      <ProgressSpinner strokeWidth="8"  animationDuration=".5s"/>
    </div>
    <Toast/>
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "app",
  components: {},
  data: function() {
    return {
      get token() {
        if ( localStorage.getItem('token')) {
          return true
        }
        return false;
      },
    };
  },
  created() {
    this.init();
  },
  
    computed: {
    ...mapGetters(["istokenSet"]),
  },
  methods: {
    ...mapActions(["getToken"]),
    init() {
      this.getToken();
    },
  },
};
</script>

<style>
#intro {
        /* background-image: url("./assets/images/cover.jpg"); */
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        display: grid;
        place-items: center;
      }
</style>