<template>
  <div>
    <v-row>
      <v-col lg="5"></v-col>
      <v-col lg="1">
        <div class="my-2">
         <v-btn color="success" fab x-large dark @click="$bvModal.show('modal-scoped')">
           <v-icon>mdi-microphone-message</v-icon>
         </v-btn>
        </div>
      </v-col>
      <v-col lg="1">
        <div class="my-2">
          <v-btn id="shoow" color="error" fab x-large dark v-b-modal.modal-xl>
            <v-icon>mdi-bullhorn-outline</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col lg="5"></v-col>
    </v-row>
    <template>
      <b-modal id="modal-scoped">
        <template #modal-header="{ close }">
          <!-- Emulate built in modal header close button action -->
          <b-button size="sm" variant="outline-danger">
            à venir
          </b-button>
          <h5>Enregistré votre message</h5>
        </template>
        <!-- <template> -->

          <div style="display: flex; justify-content: center; align-items: center">
            <audio-recorder
            :attempts="1"
            :time="5"
            :after-recording="callback"
            />
        </div>
          <!-- <v-row style="display: flex; justify-content: center; align-items: center; margin-bottom: 20px;">
            <v-row style="display: flex; justify-content: center; align-items: center; margin-bottom: 20px;">
              <v-col style="display: flex; justify-content: center; align-items: center">
                  <img src="../assets/images/gifs/icons8-play-record.gif" width="100">
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="6" style="display: flex; justify-content: center; align-items: center">
                <b-button size="sm" variant="outline-danger" @click="startTxtToSpeech">Appuyé pour enregistrer</b-button>
              </v-col>
              <v-col lg="6" style="display: flex; justify-content: center; align-items: center">
                <b-button size="sm" variant="outline-danger" @click="startSpeechToTxt"><img src="../assets/images/gifs/icons8-voice.gif" width="18">Appuyé pour écouter</b-button>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="" style="display: flex; justify-content: center; align-items: center">
                  <img src="../assets/images/gifs/icons8-audio-wave.gif" width="50">
                  <img src="../assets/images/gifs/icons8-audio-wave.gif" width="50">
                  <img src="../assets/images/gifs/icons8-audio-wave.gif" width="50">
              </v-col>
            </v-row>
             <v-row>
              <div class="speech-transciption">
                <div v-for="(word, index) in transcription_" :key="index">
                   {{ word }}
                </div>
                <div>{{ runtimeTranscription_ }}</div>
              </div>
             </v-row>
             <v-row style="margin-top: 30px;">
                <b-button size="sm" variant="success" disabled @click="envoyer_vocal()"><img src="../assets/images/gifs/send.png" width="40" style="margin-right: 8px;">ENVOYER</b-button>
             </v-row>
          </v-row> -->


        <!-- </template> -->
        <template #modal-footer="{ cancel }">
          <b >Denosterres service</b>
          <b-button size="sm" variant="danger" @click="cancel()">
            Annulé
          </b-button>
        </template>
      </b-modal>
    </template>




















    <!------------------------------------------------------PARTIE DISTRIBUTION VOCAL----------------------------->
    <!------------------------------------------------------PARTIE DISTRIBUTION VOCAL----------------------------->
    <!------------------------------------------------------PARTIE DISTRIBUTION VOCAL----------------------------->










    <b-modal id="modal-xl" size="xl" title="Audio par ethnie">
      <div class="heading">
          <h1>Ecouté</h1>
          <h4>Quelle langue parlez vous ?</h4>
      </div>
      <v-row>
      <v-col md="3" sm="6" cols="12">
          <v-card class="mx-auto" max-width="344" >
          <v-img
              src="../assets/images/note_voice/2.jpg"
              height="200px"
              ></v-img>
          <v-card-actions>
          <v-btn color="orange lighten-2" text > Malinké </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  class="mx-2"
                  fab
                  dark
                  color="indigo"
                  @click="playSound('https://denosterres.innovdurable.com/media/Recording_9.mp3')"
              >
          <v-icon dark>
              mdi-play
          </v-icon>
      </v-btn>
  </v-card-actions>
  </v-card>
</v-col>
  <v-col md="3" sm="6" cols="12">
      <v-card class="mx-auto" max-width="344" >
        <v-img
          src="../assets/images/note_voice/3.jpg"
          height="200px"
        ></v-img>
        <v-card-actions>
          <v-btn color="orange lighten-2" text > Dioula </v-btn>
          <v-spacer></v-spacer>
          <v-btn
          class="mx-2"
          fab
          dark
          color="indigo"
          @click="playSound('https://denosterres.innovdurable.com/media/Recording_15.mp3')"
          
        >
          <v-icon dark>
            mdi-play
          </v-icon>
        </v-btn>
        </v-card-actions>
      </v-card>
  </v-col>

  <v-col md="3" sm="6" cols="12">
      <v-card class="mx-auto" max-width="344" >
        <v-img
          src="../assets/images/note_voice/4.jpg"
          height="200px"
        ></v-img>
        <v-card-actions>
          <v-btn color="orange lighten-2" text > Abouré </v-btn>
          <v-spacer></v-spacer>
          <v-btn
          class="mx-2"
          fab
          dark
          color="indigo"
          @click="playSound('https://denosterres.innovdurable.com/media/Recording_13.mp3')"
        >
          <v-icon dark>
            mdi-play
          </v-icon>
        </v-btn>
        </v-card-actions>
      </v-card>
  </v-col>
  <!-- <v-col md="3" sm="6" cols="12">
      <v-card class="mx-auto" max-width="344" >
        <v-img
          src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
          height="200px"
        ></v-img>
        <v-card-actions>
          <v-btn color="orange lighten-2" text > Ibo </v-btn>
          <v-spacer></v-spacer>

          <v-btn
          class="mx-2"
          fab
          dark
          color="indigo"
          @click="playSound('https://testdenosterres.moonloop.eu/media/Recording_16.6293756a.mp3')"
        >
          <v-icon dark>
            mdi-play
          </v-icon>
        </v-btn>
    
        </v-card-actions>
      </v-card>
  </v-col> -->
  <v-col md="3" sm="6" cols="12">
      <v-card class="mx-auto" max-width="344" >
        <v-img
          src="../assets/images/note_voice/1.jpg"
          height="200px"
        ></v-img>
        <v-card-actions>
          <v-btn color="orange lighten-2" text > Baoulé </v-btn>
          <v-spacer></v-spacer>
          <v-btn
          class="mx-2"
          fab
          dark
          color="indigo"
          @click="playSound('https://denosterres.innovdurable.com/media/Recording_9.mp3')"
        >
          <v-icon dark>
            mdi-play
          </v-icon>
        </v-btn>
        </v-card-actions>
      </v-card>
  </v-col>
  <!-- <v-col md="3" sm="6" cols="12">
      <v-card class="mx-auto" max-width="344" >
        <v-img
          src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
          height="200px"
        ></v-img>
        <v-card-actions>
          <v-btn  text > Abouré </v-btn>
          <v-spacer></v-spacer>
          <v-btn
          class="mx-2"
          fab
          dark
          color="indigo"
          @click="playSound('https://testdenosterres.moonloop.eu/media/')"
        >
          <v-icon dark>
            mdi-play
          </v-icon>
        </v-btn>
        </v-card-actions>
      </v-card>
  </v-col> -->
</v-row>
  </b-modal>













    <!------------------------------------------------------///PARTIE DISTRIBUTION VOCAL----------------------------->
    <!------------------------------------------------------///PARTIE DISTRIBUTION VOCAL----------------------------->
    <!------------------------------------------------------///PARTIE DISTRIBUTION VOCAL----------------------------->

  </div>
</template>

<script>
  // import { useSound } from '@vueuse/sound'
  //import trumpetSfx from '../assets/audio/Recording_9.mp3'
  import { mapActions, mapGetters } from "vuex";

  

  export default {
      name: 'note_voice',
    data() {
      return {
        soung_url: "",
      fille: null,
        runtimeTranscription_: "",
        transcription_: [],
        lang_: "fr-FR",
        mot: "mamamia",
        l_audio: [],
        playAudio: "",
        text: '',
            playAudio: false,
            show: false,
            showModal: false,
      };
    },

    watch: {
    files() {
      if (this.files.code_http === 200 || this.files.code_http === 201) {
        console.log("C'est OK");
        this.soung_url = process.env.VUE_APP_UPLOAD + this.files["url"];
        console.log(this.soung_url);
      }
    },
  },

created () {
  //console.log("bonjour je suis note voice");
  this.showModal = true;
},

mounted () {
   //console.log('Play outside of');
   this.playAudio = true;
   this.playAut()
},

computed: {
    ...mapGetters(["files"]),
  },

  methods: {
    ...mapActions(["saveFile"]),

      // startTxtToSpeech() {
      //   // initialisation of voicereco
      //   window.SpeechRecognition =
      //     window.SpeechRecognition || window.webkitSpeechRecognition;
      //   const recognition = new window.SpeechRecognition();
      //   recognition.lang = this.lang_;
      //   recognition.interimResults = true;
  
      //   // event current voice reco word
      //   recognition.addEventListener("result", event => {
      //     const text = Array.from(event.results)
      //       .map(result => result[0])
      //       .map(result => result.transcript)
      //       .join("");
      //     this.runtimeTranscription_ = text;
      //   });
  
      //   // end of transcription
      //   recognition.addEventListener("end", () => {
      //     this.transcription_.push(this.runtimeTranscription_);
      //     this.runtimeTranscription_ = "";
      //     recognition.stop();
      //   });
      //   recognition.start();
      // },
      // startSpeechToTxt() {
      //   // start speech to txt
      //   var utterance = new SpeechSynthesisUtterance(this.transcription_);
      //   window.speechSynthesis.speak(utterance);
      // },
      play() {
        console.log("Haut parleur a été cliqué");
      },
      recording() {
        console.log("Magnetophone a été cliqué");
      },
      envoyer_vocal() {
        console.log("Envoyer le vocal a bien été cliqué");
      },
      playSound (sound) {
      if(sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },
    playAut() {
      const userdetails = JSON.parse(localStorage.getItem("user"));
      if(userdetails === null) {
        if(this.showModal === true) {
          shoow.click()
        }
      }
    },

    callback (data) {
        console.log("recoird of the sound");
        console.log(data);
        console.log("fichier mp3")

        let file = new File([data.blob], 'record.mp3', { type: 'mp3' })
        // this.inputFiles[file] = this.$refs[file].files[0].name;
        console.log(file);
        this.saveFile(file);
        console.log(data.blob);
      },
    }
  };
  </script>
  
  <style lang="scss">
  .speech-transciption {
    width: 500px;
    padding: 20px;
    border: 2px solid grey;
    background-color: rgb(211, 228, 253);
    border-radius: 20px;
  }
  .heading {
    text-align: center;
    h1 {
      background: -webkit-linear-gradient(#fff, #999);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-align: center;
      margin: 0 0 5px 0;
      font-weight: 900;
      font-size: 4rem;
      color: #fff;
    }
    h4 {
      color: lighten(#5c3d86,30%);
      text-align: center;
      margin: 0 0 35px 0;
      font-weight: 400;
      font-size: 24px;
    }
  }
  </style>






