<template>
  <main>
    <section class="about-two">
      <img
        src=""
        alt=""
        class="about-two__bg"
      />
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="about-two__images">
              <img src="../../assets/images/resources/about-2-2.jpg" alt="" />
              <img src="../../assets/images/resources/about-2-1.jpg" alt="" />
            </div>
            <!-- /.about-two__images -->
          </div>
          <!-- /.col-lg-6 -->
          <div class="col-lg-6">
            <div class="about-two__content">
              <div class="block-title">
                <div class="block-title__image"></div>
                <!-- /.block-title__image -->
                <!-- <p>Our introduction</p> -->
                <h3>DENOSTERRES</h3>
              </div>
              <!-- /.block-title -->
              <div class="about-two__summery">
                <p>
                  Cultiver vos produits en toute sérénité et une fois à terme
                  venez et vendez les avec assurance. Vous ne serez pas déçu de
                  la rapidité d'écoulement de vos produits.
                </p>
                <br />
                <p>Vous avez la posibilité :</p>
              </div>
              <!-- /.about-two__summery -->
              <div class="row">
                <div class="col-md-6">
                  <ul class="list-unstyled about-two__list">
                    <li>
                      <i class="fa fa-check-circle"></i>
                      D'acheter
                    </li>
                    <li>
                      <i class="fa fa-check-circle"></i>
                      De vendre
                    </li>
                  </ul>
                  <!-- /.list-unstyled about-two__list -->
                  <!-- <img
                    src="../../assets/images/resources/sign-1-1.png"
                    alt=""
                  /> -->
                  <span class="about-two__designation"></span>
                </div>
                <!-- /.col-md-6 -->
                <div class="col-md-6">
                  <div class="about-two__progress">
                    <!-- /.about-two__progress-box -->
                    <br />
                    <br />
                    <div class="">
                      <h5>Solutions innovante</h5>
                    </div>
                    <!-- /.about-two__progress-content -->
                  </div>
                  <!-- /.about-two__progress -->
                  <div class="about-two__progress">
                    <!-- /.about-two__progress-box -->
                    <div class="">
                      <h5>Agriculture de qualité</h5>
                    </div>
                    <!-- /.about-two__progress-content -->
                  </div>
                  <!-- /.about-two__progress -->
                </div>
                <!-- /.col-md-6 -->
              </div>
              <!-- /.row -->
            </div>
            <!-- /.about-two__content -->
          </div>

          <!-- /.col-lg-6 -->
        </div>
        <!-- Using components -->

        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>
    <!-- /.about-two -->
    <section class="feature-two">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-4">
            <div class="feature-two__box">
              <i class="fa fa-money"></i>
              <p>Nombre</p>
              <h3>
                ACHETEURS <br />
                4448
              </h3>
            </div>
            <!-- /.feature-two__box -->
          </div>
          <!-- /.col-md-12 col-lg-4 -->
          <div class="col-md-12 col-lg-4">
            <div class="feature-two__box">
              <i class="fa fa-user-plus"></i>
              <p>Nombre</p>
              <h3>
                VISITEURS <br />
                7653
              </h3>
            </div>
            <!-- /.feature-two__box -->
          </div>
          <!-- /.col-md-12 col-lg-4 -->
          <div class="col-md-12 col-lg-4">
            <div class="feature-two__box">
              <i class="fa fa-users"></i>
              <p>Nombre</p>
              <h3>
                VENDEURS <br />
                5563
              </h3>
            </div>
            <!-- /.feature-two__box -->
          </div>
          <!-- /.col-md-12 col-lg-4 -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>
            <section class="contact-infos">
            <div class="container">
                <div class="inner-container wow fadeInUp" data-wow-duration="1500ms">
                    <div class="row no-gutters">
                                <h2 class="text-center" style="margin-bottom: 50px; color: white;"><strong> NOS VALEURS </strong></h2>

                        <div class="col-sm-12 col-md-12 col-lg-4">
                            <div class="contact-infos__single">
                                <h3 class="text-center" style=" color: white;">BIO</h3>
                                <em  style=" color: white;">DE NOS TERRES propose des produits
issus de l’agriculture biologique. Nos
produits sont cultivés, stockés,
transformés, gérés et commercialisés
conformément à des spécifications
techniques précises et garantis
« biologiques ». Les intrants utilisés par
nos partenaires sont « biologiques »,
depuis l’exploitation agricole jusqu’au
marché.</em>
                            </div><!-- /.contact-infos__single -->
                        </div><!-- /.col-sm-12 col-md-12 col-lg-4 -->
                        <div class="col-sm-12 col-md-12 col-lg-4">
                            <div class="contact-infos__single">
                                <h3 class="text-center" style=" color: white;">DURABLE</h3>
                                <em style=" color: white;">Les producteurs en partenariat avec
DE NOS TERRES adoptent un système
d’agriculture biologique qui s’inscrit
pleinement dans le développement
durable. Les produits issus de l’agriculture
biologique, que nous proposons, sont
réputés pour leurs qualités nutritionnelles,
gustatives ainsi que pour la santé des
consommateurs.</em>
                            </div><!-- /.contact-infos__single -->
                        </div><!-- /.col-sm-12 col-md-12 col-lg-4 -->
                        <div class="col-sm-12 col-md-12 col-lg-4">
                            <div class="contact-infos__single">
                                <h3 class="text-center" style=" color: white;">SANTÉ</h3>
                                <em style=" color: white;">Les produits agricoles biologiques que
nous proposons sont parfaitement sain
pour la santé. Ils participent à : la
réduction des risques d’obésité, la
réduction l’exposition aux pesticides pour
une meilleure santé, la limitation de la
résistance aux antibiotiques, la diminution
des risques de maladies chronique.
                                </em>
                            </div><!-- /.contact-infos__single -->
                        </div><!-- /.col-sm-12 col-md-12 col-lg-4 -->
                    </div><!-- /.row -->
                </div><!-- /.inner-container -->
            </div><!-- /.container -->
        </section><!-- /.contact-infos -->
    <br /><br />
    <!-- <div>
    <h4 class="text-center" style="margin-top: 40px"><strong> NOS CLIENTS</strong></h4>
    </div>
    <div class="pic-ctn" style="margin-top: 10px;">
        <img src="https://picsum.photos/200/300?t=1" alt="" class="pic">
        <img src="https://picsum.photos/200/300?t=2" alt="" class="pic">
        <img src="https://picsum.photos/200/300?t=3" alt="" class="pic">
        <img src="https://picsum.photos/200/300?t=4" alt="" class="pic">
        <img src="https://picsum.photos/200/300?t=5" alt="" class="pic">
    </div>-->
    <div> 
      <section>
        <div class="container">
          <form action="#">
            <div class="row justify-content-md-center" >
              <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"> -->
                <div class="login-screen" style="border: solid 4px #7daf5e;">
                  <div class="login-box">
                    <h3 class="text-center" style="margin-bottom: 20px; color: #7daf5e">
                      NEWSLETTERS
                    </h3>
                    <div class="row gutters">
                      <div class="col-12">
                        <div class="form-group">
                          <input type="email" class="form-control" placeholder="Entrez le mail" v-model="email" />
                        </div>
                      </div>
                    </div>
                    <div class="actions clearfix">
                    <div class="row justify-content-md-center" >
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <button type="button" class="btn btn-primary btn-block" @click="submit"> Souscrire </button>
                    </div>
                    </div>
                    </div>
                  </div>
                </div>
              <!-- </div> -->
            </div>
          </form>
        </div>
      </section>
    </div>
    <!--  -->
  </main>
</template>

<script>
export default {
  name: "call_to_action__three",
  components: {},

  data() {
    return {
      email: "",
    };
  },

  methods: {
    submit() {},
  },
};
</script>

<style scoped>
.login-screen {
  position: relative;
  /* box-shadow: 5px 3px 4px #c4d2e2; */
  background: #ffffff;
  margin: 60px auto;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 15px;
}

.login-screen .login-logo {
  margin: 20px 0 30px 0;
  display: block;
}

.login-screen .login-logo > img {
  margin: 0;
  max-height: 36px;
}

.login-screen .login-box {
  padding: 30px 30px;
}

.login-screen .login-box h5 {
  margin: 0 0 20px 0;
  color: #7daf5e;
  text-align: center;
}

.login-screen .actions {
  display: block;
  margin-bottom: 20px;
  text-align: center;
}

.login-screen .actions a {
  color: #7daf5e;
  text-decoration: none;
  margin-top: 12px;
  display: block;
  font-size: 0.8rem;
}

.login-screen .actions a:hover {
  text-decoration: underline;
}

.login-screen .actions .btn {
  padding: 8px 25px;
  text-transform: uppercase;
}

.login-screen a.additional-link {
  text-decoration: none;
  margin: 30px auto 0 auto;
  text-align: center;
  display: block;
}

.login-screen a.additional-link span {
  text-decoration: underline;
  color: #7daf5e;
}

.login-screen p.info {
  padding: 0;
  margin: 0 0 20px 0;
  line-height: 150%;
  color: #7daf5e;
  text-align: center;
}

/*************** 17 C. Buttons ***************/
.btn {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border: 0;
  padding: 0.375rem 1rem;
}
.btn .icon {
  font-size: 1.4rem;
  vertical-align: middle;
  margin-top: -4px;
  display: inline-block;
}
.btn.focus,
.btn:focus {
  color: #ffffff;
}

.btn-primary {
  background: #7daf5e;
  color: #ffffff;
}
.btn-primary:hover {
  background: #7daf5e;
  color: #ffffff;
}
.btn-primary:focus {
  box-shadow: 0 0 0 3px #fcfdff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary.disabled,
.btn-primary:disabled {
  background: #7daf5e;
}

.btn:disabled {
  pointer-events: none;
}

/****************** carousel client ********************/

/* html, body {
  margin: 0;
  padding: 0;
} */

/* .pic-ctn {
  width: 100vw;
  height: 200px;
} */

@keyframes display {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  10% {
    transform: translateX(0);
    opacity: 2;
  }
  20% {
    transform: translateX(0);
    opacity: 2;
  }
  30% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(-200px);
    opacity: 0;
  }
}

.pic-ctn {
  position: relative;
  width: 100vw;
  height: 300px;
  margin-top: 15vh;
}

.pic-ctn > img {
  position: absolute;
  top: 0;
  left: calc(50% - 100px);
  opacity: 0;
  animation: display 10s infinite;
}

img:nth-child(2) {
  animation-delay: 2s;
}
img:nth-child(3) {
  animation-delay: 4s;
}
img:nth-child(4) {
  animation-delay: 6s;
}
img:nth-child(5) {
  animation-delay: 8s;
}
</style>