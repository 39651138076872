<template>
  <main>
    <section class="contact-two">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5">
            <div class="contact-two__image">
              <div class="contact-two__image-bubble-1"></div>
              <!-- /.contact-two__image-bubble-1 -->
              <div class="contact-two__image-bubble-2"></div>
              <!-- /.contact-two__image-bubble-2 -->
              <div class="contact-two__image-bubble-3"></div>
              <!-- /.contact-two__image-bubble-3 -->
              <img
                src="../../assets/images/resources/contact-1-1.jpg"
                class="img-fluid"
                alt=""
              />
            </div>
            <!-- /.contact-two__image -->
          </div>
          <!-- /.col-sm-12 col-md-12 col-lg-12 col-xl-5 -->
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-3">
            <div class="contact-two__content">
              <div class="block-title">
                <div class="block-title__image"></div>
                <!-- /.block-title__image -->
                <p>Contact now</p>
                <h3>Leave Us A Message</h3>
              </div>
              <!-- /.block-title -->
              <div class="contact-two__summery">
                <p>
                  Lorem ipsum dolor sit amet, consectetur notted adipisicing
                  elit sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua lonm andhn. Aenean tincidunt id mauris id auctor.
                  Donec at ligula lacus dignissim mi quis simply neque.
                </p>
              </div>
              <!-- /.contact-two__summery -->
            </div>
            <!-- /.contact-two__content -->
          </div>
          <!-- /.col-sm-12 col-md-12 col-lg-12 col-xl-4 -->
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4">
            <form
              action="../../assets/inc/sendemail.php"
              class="contact-one__form contact-form-validated"
            >
              <div class="row">
                <div class="col-lg-12">
                  <input type="text" name="name" placeholder="Full Name" />
                </div>
                <!-- /.col-lg-6 -->
                <div class="col-lg-12">
                  <input type="text" name="email" placeholder="Email Address" />
                </div>
                <!-- /.col-lg-6 -->
                <div class="col-lg-12">
                  <input type="text" name="phone" placeholder="Phone Number" />
                </div>
                <!-- /.col-lg-6 -->
                <div class="col-lg-12">
                  <textarea
                    name="message"
                    placeholder="Write Message"
                  ></textarea>
                </div>
                <!-- /.col-lg-12 -->
                <div class="col-lg-12">
                  <button type="submit" class="thm-btn">Send Message</button
                  ><!-- /.thm-btn -->
                </div>
                <!-- /.col-lg-12 -->
              </div>
              <!-- /.row -->
            </form>
          </div>
          <!-- /.col-sm-12 col-md-12 col-lg-12 col-xl-4 -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>
  </main>
</template>

<script>
export default {
  name: "contact_two",
  components: {},
};
</script>

<style scoped>
</style>