import HTTP from "./../../Api/auth"
import Api from "./../../Api/index"

const state = {
    // post_messages
    MESSAGE: [],
    MESSAGE_ERROR: "",
    // get_messages
    MESSAGE_RECEP: [],
    MESSAGE_RECEP_ERROR: "",
    // del_messages
    MESSAGE_DEL: [],
    MESSAGE_DEL_ERROR: "",
    // Variables dynamique
    DelSet: false,
    POSTMESSAGE: false,
// <!------------------------------ MESSAGES USER ----------------------------------------->
    MESSAGES_RECEP: [],
    MESSAGES_RECEP_ERROR: "",

}

const getters = {
    // post_message
    MESSAGE: state => state.MESSAGE,
    MESSAGE_ERROR: state => state.MESSAGE_ERROR,    
    // post_message
    MESSAGE_RECEP: state => state.MESSAGE_RECEP,
    MESSAGE_RECEP_ERROR: state => state.MESSAGE_RECEP_ERROR,
    // get_message
    MESSAGE_DEL: state => state.MESSAGE_DEL,
    MESSAGE_DEL_ERROR: state => state.MESSAGE_DEL_ERROR,
    // Variables dynamique
    DelSet: state => state.DelSet,
    POSTMESSAGE: state => state.POSTMESSAGE,
// <!------------------------------ MESSAGES USER ----------------------------------------->
    MESSAGES_RECEP: state => state.MESSAGES_RECEP,
    MESSAGES_RECEP_ERROR: state => state.MESSAGES_RECEP_ERROR,

}

const actions = {
    POST_MESSAGE({ commit }, data) {
        HTTP.post(process.env.VUE_APP_AUTHURL + "messages", data)
            .then(response => {
                commit("SET_MESSAGE", response.data)
            commit("SET_POSTMESSAGE", true)
                console.log("succes");
                   console.log(response.data);
                   console.log(true);
            })
            .catch((error) => {
                commit("SET_MESSAGE_ERROR", error)
                commit("SET_POSTMESSAGE", false)
                console.log("erreur");s
            })
    },
    GET_USER_MESSAGE({ commit }, id) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "messages/" + id)
            .then(response => {
                commit("SET_RECEP_MESSAGE", response.data)
                console.log("succes get messages");
                console.log(response.data);
            })
            .catch((error) => {
                commit("SET_RECEP_MESSAGE_ERROR", error)
                console.log("erreur get message");
            })
    },
    DEL_MESSAGE({ commit }, id) {
        HTTP.delete(process.env.VUE_APP_AUTHURL + `messages/` + id)
        .then(() => { commit("SET_DEL_MESSAGE", 'Le produit a bien été supprime')
            commit("setdel", true)
            console.log("Le produit a bien été supprimé");
        }).catch((error) => {
            commit("SET_DEL_MESSAGE_ERROR", "error")
            commit("setdel", false)
        })
    },
// <!------------------------------ MESSAGES USER ----------------------------------------->
    GET_USER_MESSAGES({ commit }) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "messagesUser")
            .then(response => {
                commit("SET_RECEP_MESSAGES", response.data)
                console.log("------------------get user pote---------------");
                console.log(response.data);
            })
            .catch((error) => {
                commit("SET_RECEP_MESSAGES_ERROR", error)
                console.log("erreur get message");
            })
    },
}

const mutations = {
    // post_message
    SET_MESSAGE: (state, hasVarList) => (state.MESSAGE = hasVarList),
    SET_MESSAGE_ERROR: (state, hasVarListE) => (state.MESSAGE_ERROR = hasVarListE),    
    // post_message
    SET_RECEP_MESSAGE: (state, recep) => (state.MESSAGE_RECEP = recep),
    SET_RECEP_MESSAGE_ERROR: (state, recep) => (state.MESSAGE_RECEP_ERROR = recep),
    // del_message
    SET_DEL_MESSAGE: (state, del) => (state.MESSAGE_DEL = del),
    SET_DEL_MESSAGE_ERROR: (state, del) => (state.MESSAGE_DEL_ERROR = del),
    // Variables dynamique
    setdel: (state, hasDel) => (state.DelSet = hasDel),
    SET_POSTMESSAGE: (state, hasPstl) => (state.POSTMESSAGE = hasPstl),
// <!------------------------------ MESSAGES USER ----------------------------------------->
    SET_RECEP_MESSAGES: (state, recep) => (state.MESSAGES_RECEP = recep),
    SET_RECEP_MESSAGES_ERROR: (state, recep) => (state.MESSAGES_RECEP_ERROR = recep),
}


export default {
    state,
    mutations,
    getters,
    actions
}