<template>
    <main>
      <facebook-login class="button"
        appId="377321437903874"
        @login="onLogin"
        @logout="onLogout"
        @sdk-loaded="sdkLoaded">
    </facebook-login>
    </main>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import facebookLogin from 'facebook-login-vuejs';
  
  export default {
    // name: "ins_face",
    components: {
      facebookLogin
    },
    data() {
      return {
        picture: '',
        profil: "VENDEUR",
        isConnected: false,
        naame: '',
        noom: '',
        prenoom: '',
        eemail: '',
        personalID: '',
        FB: undefined,
      }
    },
    
    watch: {
      msgErrorClient() {
      if (this.msgErrorClient) {
        if (this.msgErrorClient.response.data.code_message === "ERR_EMAIL_UTILISE") {
          window.location.reload()
        }
      }
    },
  },

    computed: {
      ...mapGetters(["msgErrorClient"])
    },
    methods: {
      ...mapActions(["addClient"]),
  
      getUserData() {
        this.FB.api('/me', 'GET', { fields: 'id,name,email,picture' },
          userInformation => {
            console.warn("data api",userInformation)
            this.personalID = userInformation.id;
            this.naame = userInformation.name;
            this.eemail = userInformation.email;
            this.picture = userInformation.picture.data.url;
            console.log(this.picture);
            let last_first_name = this.naame.split(' ')
            this.prenoom = last_first_name[0]
            this.noom = last_first_name[1]
            let newUserFB = {
              nom: this.noom,
              prenom: this.prenoom,
              email: this.eemail,
              provider_id: this.personalID,
              profil: this.profil
            };
            console.log(newUserFB);
            if (userInformation !== null) {
              this.addClient(newUserFB);
            }
          }
        )
      },
      sdkLoaded(payload) {
        this.isConnected = payload.isConnected
        this.FB = payload.FB
        // if (this.isConnected) this.getUserData()
      },
      onLogin() {
        this.isConnected = true
        this.getUserData()
      },
      onLogout() {
        this.isConnected = false;
      },
    }
  }
  </script>
  
  
  <style scoped>
  /* @import "../assets/css/css.All.Element/cssConnexion.css"; */
  
  /* ------------------ button facebook and google -------------------- */
  
  </style>