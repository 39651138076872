<template>
  <main>
    <header class="main-header card fixed-header indexx">
      <b-navbar toggleable="lg" type="light" variant="white">
        <b-navbar-brand href="#">
          <router-link to="/"><img src="../../assets/logo.png" width="170" alt=""></router-link>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>               
            <b-nav-item href="#"> <router-link to="/"><h6> <strong>ACCUEIL</strong></h6></router-link></b-nav-item>
            <b-nav-item href="#"> <router-link to="/about"><h6><strong>A PROPOS</strong></h6></router-link></b-nav-item>
            <b-nav-item href="#"> <router-link to="/services"><h6><strong>MAGASIN</strong></h6></router-link></b-nav-item>
            <b-nav-item href="#"> <router-link to="/contact"><h6><strong>CONTACT</strong></h6></router-link></b-nav-item>
            <!-- <Publicationcompo v-if="user.profil === 'VENDEUR'" /> -->
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <span v-if="!isUserLogged()">
                <router-link to="/connexion" > <b-button pill variant="success" style="margin-right: 10px" >Connexion</b-button ></router-link  >
                <router-link to="/inscription/acheteur"> <b-button pill variant="outline-secondary" >Inscription</b-button ></router-link >
            </span>
            <span v-if="isUserLogged()" style="margin-right: 25px;">

          <v-menu>
            <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" style="cursor: pointer" class="mx-5 mr-10">
                      <!-- <v-badge offset-x="10" offset-y="10" color="red" content="5">
                          <v-icon>mdi-bell</v-icon>
                      </v-badge> -->
                      <v-badge 
                      :content="notification_length"
                      :value="notification_length"
                      color="green" 
                      overlap>
                      <v-icon large> mdi-bell </v-icon>
                    </v-badge>
                  </span>
            </template>
            <v-list width="320" class="py-0">
              <v-list-item two-line link v-for="notif in all_notifications" :key="notif.id">
                  <v-list-item-avatar>
                    <img src="https://testdenosterres.moonloop.eu/api/updload/f_20220824171344.jpg" alt="">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <!-- <h6>dsqfdq</h6> -->
                      <v-list-item-title style="color: green">
                          {{ notif.created_at.substr(8,9).substr(0, 2) }}
                          {{ notif.created_at.substr(5,6).substr(0, 2) }}
                          {{ notif.created_at.substr(0,4) }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ notif.data.reception }}
                      </v-list-item-subtitle>
                  </v-list-item-content>

              </v-list-item>
          </v-list>
         </v-menu>
        </span>
          <br>
            <!-- <v-icon>mdi-bell</v-icon> -->
            <span v-if="isUserLogged()">
              <div>
                <b-nav-item-dropdown id="my-nav-dropdown" text="Mon compte" toggle-class="nav-link-custom" right >
                  <b-dropdown-item v-if="!if_admin()"><router-link to="/profil" >Profil</router-link></b-dropdown-item>
                  <b-dropdown-item v-if="if_admin()"><router-link to="/index_admin" >Profil</router-link></b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="userLogout0()">Se déconnecter</b-dropdown-item>
                </b-nav-item-dropdown>
              </div>
            </span>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </header>
  </main>
</template>

<script>
import Publicationcompo from "../Publicationcompo.vue";
import { mapActions, mapGetters } from "vuex";


export default {
  name: "main-header",
  props: {},
  components: {
    Publicationcompo,
  },
  data: () => ({
    user: {
      nom: "",
      profil: "",
    },
    all_notifications: [],
    notification_length: "",
  }),
  watch: {
    NOTIFICATION_RECEP() {
      console.log("le get notification à maché");
      console.log(this.NOTIFICATION_RECEP);
      console.log(this.NOTIFICATION_RECEP.notification);
      this.all_notifications = this.NOTIFICATION_RECEP.notification;
      console.log(this.NOTIFICATION_RECEP.notification.length);
      this.notification_length = this.NOTIFICATION_RECEP.notification.length
      // console.log(this.NOTIFICATION_RECEP.notification.data);
      // console.log(this.NOTIFICATION_RECEP.notification.data.reception);
    }
  },

  created() {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if(userDetails !== null) {
      this.user.nom = userDetails.nom;
      this.user.profil = userDetails.profil;
    return
    }
    // console.log(this.user.nom);
  },
  mounted() {
    if(this.isUserLogged()) {
      this.GET_USER_NOTIFICATION()
    }
  },
  computed: {
    ...mapGetters(["NOTIFICATION_RECEP"]),
  },
  methods: {
    ...mapActions(["GET_USER_NOTIFICATION"]),

    if_admin() {
      const userdetails = JSON.parse(localStorage.getItem("user"));
      if (userdetails.profil === "SUPER_ADMIN" || userdetails.profil === "ADMIN") {
        // this.$router.push("/index_admin");
      return true;
      }
    },
    isUserLogged() {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if(userDetails !== null) {
    return true
    } return false
    },
    userLogout0() {
      localStorage.removeItem("user");
      localStorage.removeItem("userauth");
      localStorage.removeItem("token");
      localStorage.removeItem("fblst_377321437903874");
      // this.$router.push("/");
      window.location.href = '/'

    },
  },
};
</script>

<style scoped>
div.card {
  /* width: 0px; */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  /* text-align: center; */
}
.fixed-header {
  width: 100%;
  position: fixed;
  /* background: #333; */
  /* padding: 10px 0; */
  color: #fff;
}
.fixed-header {
  top: 0;
}
.indexx {
  z-index: 1000;
}
</style>