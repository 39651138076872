<template>
  <main>
    <section class="about-one">
      <img
        src="../../assets/images/icons/about-bg-icon-1-1.png"
        class="about-one__bg-shape-1"
        alt=""
      />
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="about-one__images">
              <img src="../../assets/images/resources/about-1-1.jpg" alt="" />
              <img src="../../assets/images/resources/about-1-2.jpg" alt="" />
              <div
                class="about-one__count wow fadeInLeft"
                data-wow-duration="1500ms"
              >
                <span>Trusted by</span>
                <h4>8900</h4>
              </div>
              <!-- /.about-one__count -->
            </div>
            <!-- /.about-one__images -->
          </div>
          <!-- /.col-lg-6 -->
          <div class="col-lg-7">
            <div class="about-one__content">
              <div class="block-title text-left">
                <div class="block-title__image"></div>
                <!-- /.block-title__image -->
                <p>Welcome to agricon</p>
                <h3>Better Agriculture for Better Future</h3>
              </div>
              <!-- /.block-title -->
              <div class="about-one__tagline">
                <p>
                  We have 30 years of agriculture & eco farming experience
                  globaly
                </p>
              </div>
              <!-- /.about-one__tagline -->
              <div class="about-one__summery">
                <p>
                  There are many variations of passages of lorem ipsum available
                  but the majority have suffered alteration in some form by
                  injected humor or random word which don't look even.
                </p>
              </div>
              <!-- /.about-one__summery -->
              <div class="about-one__icon-row">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="about-one__box">
                      <i class="agrikon-icon-farmer"></i>
                      <h4><a href="about.html">Professional Farmers</a></h4>
                    </div>
                    <!-- /.about-one__box -->
                  </div>
                  <!-- /.col-lg-6 -->
                  <div class="col-lg-6">
                    <div class="about-one__box">
                      <i class="agrikon-icon-agriculture"></i>
                      <h4>
                        <a href="services.html">Organic & Eco Solutions</a>
                      </h4>
                    </div>
                    <!-- /.about-one__box -->
                  </div>
                  <!-- /.col-lg-6 -->
                </div>
                <!-- /.row -->
              </div>
              <!-- /.about-one__icon-row -->
              <a href="about.html" class="thm-btn">Discover More</a
              ><!-- /.thm-btn -->
            </div>
            <!-- /.about-one__content -->
          </div>
          <!-- /.col-lg-6 -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>
  </main>
</template>

<script>
export default {
  name: "about_one",
  components: {},
};
</script>

<style scoped>
</style>