<template>
  <main>
    <!-- <div> -->
    <!-- /.client-carousel -->
    <footer class="site-footer fixed-footer">
      <img
        src="assets/images/icons/footer-bg-icon-1.png"
        class="site-footer__shape-1"
        alt=""
      />
      <img
        src="assets/images/icons/footer-bg-icon-2.png"
        class="site-footer__shape-2"
        alt=""
      />
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <div class="footer-widget">
              <a href="index.html" class="footer-widget__Logo">
                <img src="assets/images/logo-light.png" width="153" alt="" />
              </a>
              <p>
                <strong
                  >Nous sommes une plateforme de mise en relation des acheteurs
                  et des agriculteurs de la Cote d’Ivoire</strong
                >
              </p>
              <br /><br />
              <!-- <form action="#" data-url="YOUR_MAILCHIMP_URL" class="mc-form">
                <input type="email" name="EMAIL" placeholder="Email Address" />
                <button type="submit">
                  <i class="agrikon-icon-right-arrow"></i>
                </button>
              </form> -->
              <!-- /.mc-form -->
              <!-- <div class="mc-form__response"></div> -->
              <!-- /.mc-form__response -->
              <!-- <div class="footer__social">
                <a href="#" class="fab fa-facebook-square"></a>
                <a href="#" class="fab fa-twitter"></a>
                <a href="#" class="fab fa-pinterest-p"></a>
                <a href="#" class="fab fa-instagram"></a>
              </div> -->
              <!-- /.topbar__social -->
            </div>
            <!-- /.footer-widget -->
          </div>
          <!-- /.col-sm-12 col-md-6 col-lg-4 -->
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-2">
            <div class="footer-widget footer-widget__links-widget">
              <h3 class="footer-widget__title">Pages</h3>
              <!-- /.footer-widget__title -->
              <ul class="list-unstyled footer-widget__links">
                <li>
                  <router-link to="/"> Accueil</router-link>
                </li>
                <li>
                  <router-link to="/about">A propos</router-link>
                </li>
                <li>
                  <router-link to="/services">Magasin</router-link>
                </li>
                <li>
                  <router-link to="/connexion">Connexion</router-link>
                </li>
              </ul>
              <!-- /.list-unstyled -->
            </div>
            <!-- /.footer-widget -->
          </div>
          <!-- /.col-sm-12 col-md-6 col-lg-2 -->
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div class="footer-widget">
              <div class="footer-widget footer-widget__links-widget">
                <h3 class="footer-widget__title">
                  Nos produits par categories
                </h3>
                <!-- /.footer-widget__title -->
                <ul class="list-unstyled footer-widget__links">
                  <li><a href="#">Fruits</a></li>
                  <li><a href="#">Legumes</a></li>
                  <li><a href="#">Tubercule</a></li>
                  <li><a href="#">Noix</a></li>
                  <li><a href="#">Céréales</a></li>
                </ul>
                <!-- /.list-unstyled -->
              </div>
              <!-- /.list-unstyled footer-widget__post -->
            </div>
            <!-- /.footer-widget -->
          </div>
          <!-- /.col-sm-12 col-md-6 col-lg-3 -->
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <h3 class="footer-widget__title">Contact</h3>
            <!-- /.footer-widget__title -->
            <ul class="list-unstyled footer-widget__contact">
              <li>
                <i class="agrikon-icon-telephone"></i>
                <a href="tel:+33 61 36 16 0 55">+33 61 36 16 0 55</a>
              </li>
              <li>
                <i class="agrikon-icon-email"></i>
                <a href="mailto:contact@denoterre.com">contact@denoterre.com</a>
              </li>
              <li>
                <i class="agrikon-icon-pin"></i>
                <a href="#">Côte d'Ivoire, Abidjan Cocody, 8ème tranche</a>
              </li>
            </ul>
            <!-- /.list-unstyled -->
          </div>
          <!-- /.col-sm-12 col-md-6 col-lg-3 -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </footer>
    <!-- /.site-footer -->
    <!-- <div class="bottom-footer"> -->
    <!-- <div class="container">
      <p>© Copyright 2020 by Company.com</p>
      <div class="bottom-footer__links">
        <a href="#">Terms & Conditions</a>
        <a href="#">Privacy Policy</a>
        <a href="#">Sitemap</a>
      </div>
      <!- /.bottom-footer__links -->
    <!--</div> -->
    <!-- /.container -->
    <!-- /.bottom-footer -->
    <!-- </div> -->
  </main>
</template>

<script>
export default {
  name: "Footer",
  props: {},
};
</script>

<style scoped>
.fixed-footer {
  bottom: 0;
}
</style>
