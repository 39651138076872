<template>
  <main>
    <section class="about-three">
      <div class="container">
        <div class="row row1">
          <center>
            <h3><strong>Annonces récemment publiées</strong></h3>
          </center>
          <div class="card">
            <CarouselB
              :value="listeProduits.donnees"
              :numVisible="3"
              :numScroll="3"
              class="custom-carousel"
              :circular="true"
              :autoplayInterval="3000"
            >
              <template #header>
                <h5></h5>
              </template>
              <template #item="slotProps">
                <div>
                  <!--------------------------------- Iteration des differents produits --------------------------------->
                  <div class="" style="margin-right: 10px">
                    <!-------------------------------End iteration des differents produits ------------------------------>
                    <div class="blog-card">
                      <div class="blog-card__image">
                        <img
                          :src="slotProps.data.images"
                          alt="picture"
                          height="244"
                        />
                      </div>
                      <!-- /.blog-card__image -->
                      <div class="blog-card__content">
                        <!-------------------------------Libelle du produit ------------------------------>
                        <h3>
                          <a href="">{{ slotProps.data.libelle_produit }}</a>
                        </h3>
                        <div class="blog-card__meta">
                          <h5>{{ slotProps.data.prix_produit }} Fcfa</h5>
                          <!-- <a href=""><i class="far fa-comments"></i> 2 Comments</a> -->
                        </div>
                        <div class="row">
                          <div class="col md 3">
                            <a
                              class="thm-btn"
                              @click="redirection(slotProps.data.id)"
                            >
                              <span style="color: white"> Voir</span></a
                            >
                          </div>
                          <div class="col md 9">
                            <h5>
                              Quantité :
                              {{ slotProps.data.quantite_produit }}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <!-- /.blog-card__content -->
                    </div>
                    <!-- /.blog-card -->
                  </div>
                </div>
              </template>
            </CarouselB>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "carouselPrime",
  watch: {
    listeProduits() {
      if (this.listeProduits) {
      }
    },
  },
  computed: {
    ...mapGetters(["listeProduits"]),
  },
  mounted() {
    this.getProduits();
    console.log();
  },
  methods: {
    ...mapActions(["getProduits"]),
    redirection(produit) {
      const path = `/detailduproduit/` + produit;
      if (this.$route.path !== path)
        this.$router.push({ path, params: { id: produit } });
    },
    sloww(variableCommeCa) {
      console.log("la valeur du slot au tour actuelle est : ");
      console.log(variableCommeCa);
    },
  },
};
</script>

<style scoped>
.about1 {
  margin-top: 100px;
}
.blog-card {
  box-shadow: 5px;
}
</style>