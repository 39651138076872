import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import EnregVendeur from "../components/EnregVendeur.vue";
import EnregAcheteur from "../components/EnregAcheteur.vue";

import Dashboard from "../views/admin/admin_dashboard.vue";

import produits from "../views/admin/admin_products.vue";
import commandes from "../views/admin/admin_commandes.vue";
import profil_utilisateur from "../views/admin/admin_profil_utilisateur.vue";
import profil_administrateur from "../views/admin/admin_profil_administrateur.vue";
import reglage from "../views/admin/admin_reglage.vue";
import details_produit from "../views/admin/admin_details_produit.vue";
import commande_update from "../views/Commande_update.vue"
import modifier from "../views/admin/admin_update_prod.vue"
import detail_commande from "../views/admin/admin_details_cmde.vue"


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: "/inscription",
    name: "Inscription",
    component: () =>
      import(/* webpackChunkName: "inscription" */ "../views/Inscription.vue"),
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'vendeur',
        component: EnregVendeur,
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'acheteur',
        component: EnregAcheteur,
      },
    ],
  },
  {
    path: "/commande",
    name: "Commande",
    component: () =>
      import(/* webpackChunkName: "Commande" */ "../views/Commande_user.vue"),
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'modification_commande/:id',
        component: commande_update,
      },
    ],
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/modificationProduit/:id",
    name: "modificationProduct",
    component: () =>
      import(/* webpackChunkName: "modificationProduct" */ "../views/ModificationProduit.vue")
  },
  {
    path: "/services",
    name: "Services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/Services.vue")
  },
  {
    path: "/servicesListe",
    name: "ServicesListe",
    component: () =>
      import(/* webpackChunkName: "servicesListe" */ "../views/ServicesListe.vue")
  },
  {
    path: "/connexion",
    name: "Connexion",
    component: () =>
      import(/* webpackChunkName: "connexion" */ "../views/Connexion.vue")
  },
  {
    path: "/publication",
    name: "Publication",
    component: () =>
      import(/* webpackChunkName: "publication" */ "../views/Publication.vue")
  },
  {
    path: "/profil",
    name: "Profil",
    component: () =>
      import(/* webpackChunkName: "profil" */ "../views/Profil.vue")
  },
  {
    path: "/detailduproduit/:id",
    name: "Detailsduproduit",
    component: () =>
      import("../views/Detailduproduit.vue")
  },
  {
    path: "/modificationUtilisateur",
    name: "ModificationUser",
    component: () =>
      import(/* webpackChunkName: "modificationUtilisateur" */ "../views/ModificationUtilisateur.vue")
  },
  {
    path: "/pageEx",
    name: "PageEx",
    component: () =>
      import(/* webpackChunkName: "pageEx" */ "../views/PageEx.vue")
  },
  {
    path: "/pageEx2",
    name: "PageEx2",
    component: () =>
      import(/* webpackChunkName: "pageEx2" */ "../views/PageEx2.vue")
  },
  {
    path: "/pageEx3",
    name: "PageEx3",
    component: () =>
      import(/* webpackChunkName: "pageEx3" */ "../views/PageEx3.vue")
  },
  {
    path: "/annonceCree",
    name: "AnnonceCree",
    component: () =>
      import(/* webpackChunkName: "AnnonceCree" */ "../views/AnnonceCree.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "Contact" */ "../views/Contact.vue")
  },
  {
    path: "/completeInscription",
    name: "CompleteInscription",
    component: () =>
      import(/* webpackChunkName: "CompleteInscription" */ "../views/CompleteInscription.vue")
  },
  {
    path: "/produitsUtilisateur",
    name: "ProduitsUtilisateur",
    component: () =>
      import(/* webpackChunkName: "ProduitsUtilisateur" */ "../views/ProduitsUtilisateur.vue")
  },
  {
    path: "/panier",
    name: "Panier",
    component: () =>
      import(/* webpackChunkName: "Panier" */ "../views/Panier.vue")
  },
  {
    path: "/index_admin",
    name: "Index_admin",
    component: () =>
      import(/* webpackChunkName: "inscription" */ "../views/admin/Index_admin.vue"),
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '',
        component: Dashboard,
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'produits',
        component: produits,
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'commandes',
        component: commandes,
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: '/detail_commandes/:id',
        component: detail_commande,
      },
      // {
      //   // UserPosts will be rendered inside User's <router-view>
      //   // when /user/:id/posts is matched
      //   path: 'commandes',
      //   component: commandes,
      // },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'profil_utilisateur',
        component: profil_utilisateur,
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'profil_administrateur',
        component: profil_administrateur,
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'reglage',
        component: reglage,
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: '/details_produit/:id',
        component: details_produit,
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: '/modifier/:id',
        component: modifier,
      },
    ],
  },
  {
    path: "/categorie_fruit",
    name: "Categorie_fruit",
    component: () =>
      import(/* webpackChunkName: "Categorie_fruit" */ "../views/categories_produit/categorie_fruit.vue")
  },
  {
    path: "/categorie_cereale",
    name: "Categorie_cereale",
    component: () =>
      import(/* webpackChunkName: "Categorie_cereale" */ "../views/categories_produit/categorie_cereale.vue")
  },
  {
    path: "/categorie_noix",
    name: "Categorie_noix",
    component: () =>
      import(/* webpackChunkName: "Categorie_noix" */ "../views/categories_produit/categorie_noix.vue")
  },  
  {
    path: "/categorie_tubercule",
    name: "Categorie_tubercule",
    component: () =>
      import(/* webpackChunkName: "Categorie_tubercule" */ "../views/categories_produit/categorie_tubercule.vue")
  },  
  {
    path: "/categorie_legume",
    name: "Categorie_legume",
    component: () =>
      import(/* webpackChunkName: "Categorie_legume" */ "../views/categories_produit/categorie_legume.vue")
  },




















];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
