import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import jQuery from "jquery"
import store from "./store";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueCarousel from "vue-carousel";
import facebookLogin from 'facebook-login-vuejs';
//--------------------------------------------------------------------------------------------

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
//--------------------------------------------------------------------------------------------

import PrimeVue from 'primevue/config';
import Button from 'primevue/button'
import InputText from 'primevue/inputtext';
import Carousel from 'primevue/carousel';
import DataView from "primevue/dataview";
import Panel from "primevue/panel";
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Paginator from 'primevue/paginator';
import ToastService from 'primevue/toastservice';
import FileUpload from 'primevue/fileupload';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import ProgressSpinner from 'primevue/progressspinner';
import Vuelidate from 'vuelidate'
import vuetify from './plugins/vuetify'
// import VueRecord from '@codekraft-studio/vue-record'
import VueAuthenticate from 'vue-authenticate'
import AudioRecorder from 'vue-audio-recorder'
import SplitButton from 'primevue/splitbutton';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Embed from 'v-video-embed'
import Dialog from 'primevue/dialog';

//--------------------------------------------------------------------------------------------

import "./assets/css/main.css"
import "./assets/css/bootstrap.min.css"
import "./assets/css/fontawesome-all.min.css"
import "./assets/css/swiper.min.css"
import "./assets/css/animate.min.css"
import "./assets/css/odometer.min.css"
import "./assets/css/jarallax.css"
import "./assets/css/magnific-popup.css"
import "./assets/css/bootstrap-select.min.css"
import "./assets/css/agrikon-icons.css"
import "./assets/css/nouislider.min.css"
import "./assets/css/nouislider.pips.css"
import "./assets/css/bootstrap441.min.css"


//--------------------------------------------------------------------------------------------

Vue.component('Badge', Badge);
Vue.component('BadgeDirective', BadgeDirective);
Vue.component('InputText', InputText);
Vue.component('Button', Button);
Vue.component('CarouselB', Carousel);
Vue.component('DataView', DataView);
Vue.component('Panel', Panel);
Vue.component('Avatar', Avatar);
Vue.component('AvatarGroup', AvatarGroup);
Vue.component('Paginator', Paginator);
Vue.component('Toast', Toast);
Vue.component('FileUpload', FileUpload);
Vue.component('ConfirmDialog', ConfirmDialog);
Vue.component('ProgressSpinner', ProgressSpinner);
Vue.component('SplitButton', SplitButton);
Vue.component('Dialog', Dialog);
//--------------------------------------------------------------------------------------------

Vue.use(Vuelidate)
// Vue.use(VueRecord)
Vue.use(facebookLogin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCarousel)
Vue.use(PrimeVue)
Vue.use(ConfirmationService);
Vue.use(ToastService);
Vue.use(AudioRecorder)
Vue.use(Embed);

import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
Vue.use(Vuesax)

//-----------------------------------------  vue2-leaflet ---------------------------------------------------
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

import 'leaflet/dist/leaflet.css';
import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
//--------------------------------------------------------------------------------------------




//////////////////////////////////////////////////////////////////////////////////////////////
// Vue.use(VueAxios, axios)
// Vue.use(VueAuthenticate, {
//   baseUrl: 'http://localhost:8081', // Your API domain
//   providers: {
//     github: {
//       clientId: '141245864895-41qlgf0fnd8gfav1rulrat78nanst69p.apps.googleusercontent.com',
//       redirectUri: 'http://localhost:8081/connexion' // Your client app URL
//     }
//   }
// })
//////////////////////////////////////////////////////////////////////////////////////////////

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
