import HTTP from "../../Api/auth"
import Api from "../../Api/index"

const state = {
    // get_NOTIFICATIONS
    NOTIFICATION_RECEP: [],
    NOTIFICATION_RECEP_ERROR: "",
}

const getters = {   
    // post_NOTIFICATION
    NOTIFICATION_RECEP: state => state.NOTIFICATION_RECEP,
    NOTIFICATION_RECEP_ERROR: state => state.NOTIFICATION_RECEP_ERROR,
}

const actions = {
    GET_USER_NOTIFICATION({ commit }) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "notifications")
            .then(response => {
                commit("SET_RECEP_NOTIFICATION", response.data)
                console.log("succes get notifications");
                console.log(response.data);
            })
            .catch((error) => {
                commit("SET_RECEP_NOTIFICATION_ERROR", error)
                console.log("erreur get notifications");
            })
    },
}

const mutations = {
    // post_NOTIFICATION
    SET_RECEP_NOTIFICATION: (state, n_rcp) => (state.NOTIFICATION_RECEP = n_rcp),
    SET_RECEP_NOTIFICATION_ERROR: (state, n_rcp) => (state.NOTIFICATION_RECEP_ERROR = n_rcp),
}


export default {
    state,
    mutations,
    getters,
    actions
}